import {
    Alert,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import { useMap } from 'es-map-widget';
import React from 'react';
import { Link } from 'react-router-dom';
import { OVERVIEW_STATES } from '../../../constants';
import { useApi } from '../../../hooks/useApi';
import { useAppControl } from '../../../providers/AppContext';
import { useLayout } from '../../../providers/LayoutContext';

const MeparBlock = () => {
    const { selectedProduct, setSelectedProduct } = useLayout();
    const { year, setOverviewState } = useAppControl();
    const map = useMap();

    const handleClose = () => {
        setOverviewState(OVERVIEW_STATES.CLOSED);
        setSelectedProduct(null);
        map.setSelected(null);
    };

    const [result, loading, error] = useApi(
        (api, params) => api.mepars.getMepar({ code: selectedProduct.code, year }, params),
        [selectedProduct.code, year]
    );

    return (
        <Grid
            container
            sx={{
                p: 3,
                height: '100%',
                backgroundColor: '#fff',
                overflow: 'auto',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
            }}
            alignItems="flex-start"
            alignContent="flex-start"
        >
            {loading && (
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
            )}
            {error && (
                <Grid item xs={12}>
                    <Alert severity="error">
                        Nem található adat a {selectedProduct.code} kódú meparhoz {year}-ben.
                    </Alert>
                </Grid>
            )}
            {result && !error && (
                <Grid item xs={12}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Kód:</TableCell>
                                <TableCell>
                                    <Link
                                        to={`/products/mepar/${result.code}`}
                                        onClick={handleClose}
                                    >
                                        {result.code}
                                    </Link>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Terület:</TableCell>
                                <TableCell>{result.area}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Igényelt terület:</TableCell>
                                <TableCell>{result.areaRequested}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Lejtős terület:</TableCell>
                                <TableCell>{result.areaSloped}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Támogatható terület:</TableCell>
                                <TableCell> {result.areaSupported}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ország: </TableCell>
                                <TableCell>{result.country}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Táblák száma:</TableCell>
                                <TableCell>{result.fields?.length}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Nitrát érzékeny terület:</TableCell>
                                <TableCell>{result.nitrate ? 'Igen' : 'Nem'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Settlement:</TableCell>
                                <TableCell>{result.settlement}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Riasztások:</TableCell>
                                <TableCell>
                                    {result.alerts.map((e) => (
                                        <div key={e.id}>
                                            {format(new Date(e.createdAt), 'yyyy-MM-dd')}
                                        </div>
                                    ))}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            )}
        </Grid>
    );
};

export default MeparBlock;
