import { TextField } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { getDate, getMonth } from 'date-fns';
import { useMap } from 'es-map-widget';
import React, { useCallback, useEffect, useState } from 'react';
import { MAP_LAYER_NAMES } from '../../../constants/MapLayerNames';
import { useAppControl } from '../../../providers/AppContext';
import { useMenu } from '../contexts/MenuContext';

const HarvestDataController = ({ isLayerVisible }) => {
    const map = useMap();
    const { year } = useAppControl();
    const { harvestMonitoringData, setHarvestMonitoringData } = useMenu();
    const [minDate, setMinDate] = useState(new Date(year, 0, 1));
    const [maxDate, setMaxDate] = useState(new Date(year, 11, 31));

    const setHarvestMonitoring = useCallback(
        (newValue) => {
            setHarvestMonitoringData(newValue);
            map.setLayerData(MAP_LAYER_NAMES.HARVEST_MONITORING, newValue);
            map.setLayerVisibility(MAP_LAYER_NAMES.HARVEST_MONITORING, isLayerVisible);
        },
        [map, setHarvestMonitoringData]
    );

    useEffect(() => {
        setMinDate(new Date(year, 0, 1));
        setMaxDate(new Date(year, 11, 31));
    }, [year, setMinDate, setMaxDate]);

    useEffect(() => {
        map.setLayerData(MAP_LAYER_NAMES.HARVEST_MONITORING, { isLayerShown: isLayerVisible });
        map.setLayerVisibility(MAP_LAYER_NAMES.HARVEST_MONITORING, isLayerVisible);
    }, [isLayerVisible]);

    useEffect(() => {
        setHarvestMonitoringData((prevData) => ({
            ...prevData,
            date: new Date(year, getMonth(prevData.date), getDate(prevData.date)),
        }));
    }, [year, setHarvestMonitoringData]);

    return (
        <StaticDatePicker
            views={['day']}
            inputFormat="yyyy-MM-dd"
            displayStaticWrapperAs="desktop"
            disableFuture
            minDate={minDate}
            maxDate={maxDate}
            value={harvestMonitoringData.date}
            onChange={(date) =>
                setHarvestMonitoring({
                    isLayerShown: isLayerVisible,
                    date,
                })
            }
            renderInput={(params) => <TextField {...params} />}
        />
    );
};

export default HarvestDataController;
