import { FormGroup } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useCallback } from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/MapLayerNames';
import { useMenu } from '../contexts/MenuContext';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';

const FieldDataController = () => {
    const map = useMap();
    const { handleItemCheck, fieldData, setFieldData } = useMenu();

    const setFields = useCallback(
        (newValue) => {
            map.setLayerData(MAP_LAYER_NAMES.FIELDS, newValue);
            setFieldData(newValue);
        },
        [map, setFieldData]
    );

    return (
        <FormGroup>
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.TABLE_ALL}
                checkbox={{
                    checked: fieldData.all,
                    onChange: (event) => {
                        const isChecked = event.target.checked;
                        setFields({
                            all: true,
                            connecting: false,
                        });
                        if (isChecked) {
                            handleItemCheck(CONTROLLER_KEYS.TABLE_ALL, CONTROLLER_KEYS.TABLE);
                            handleItemCheck(
                                CONTROLLER_KEYS.TABLE_CONNECTING,
                                CONTROLLER_KEYS.TABLE
                            );
                        }
                    },
                }}
            />
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.TABLE_CONNECTING}
                checkbox={{
                    checked: fieldData.connecting,
                    onChange: (event) => {
                        const isChecked = event.target.checked;
                        setFields({
                            all: false,
                            connecting: true,
                        });
                        if (isChecked) {
                            handleItemCheck(CONTROLLER_KEYS.TABLE_ALL, CONTROLLER_KEYS.TABLE);
                            handleItemCheck(
                                CONTROLLER_KEYS.TABLE_CONNECTING,
                                CONTROLLER_KEYS.TABLE
                            );
                        }
                    },
                }}
            />
        </FormGroup>
    );
};

export default FieldDataController;
