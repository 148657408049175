import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';

const RequestedArea = ({ data }) => {
    return (
        <Accordion elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography variant="h6">Területek összesítése blokkhasználat szerint</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Blokk</TableCell>
                                <TableCell>Igényelt terület (ha)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.mepars.map((mepar, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <TableRow key={i}>
                                    <TableCell>{mepar.code}</TableCell>
                                    <TableCell>{mepar.areaRequested.toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default RequestedArea;
