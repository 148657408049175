import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { useMenu } from '../contexts/MenuContext';

const SubMenuCheckbox = ({ checked, label, value, menuKey, onChange, disabled = false }) => {
    const { handleItemCheck } = useMenu();
    return (
        <FormControlLabel
            control={
                <Checkbox
                    size="small"
                    checked={checked}
                    onChange={(event) => {
                        onChange(event);
                        if (menuKey) {
                            handleItemCheck(value || label, menuKey);
                        }
                    }}
                    className="map-controller-checkbox"
                    disabled={disabled}
                />
            }
            value={label}
            label={label}
            className="map-controller-checkbox-label"
            disableTypography
        />
    );
};

export default SubMenuCheckbox;
