import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OVERVIEW_STATES } from '../../constants';
import { MAP_LAYER_NAMES } from '../../constants/MapLayerNames';
import { useAppControl } from '../../providers/AppContext';
import { useAuth } from '../../providers/AuthProvider';
import { useLayout } from '../../providers/LayoutContext';
import NavDrawer from './NavDrawer';
import SearchForm from './SearchForm';
import UserMenu from './UserMenu';
import YearSelector from './YearSelector';

const Navbar = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { setSelectedProduct } = useLayout();
    const { setOverviewState } = useAppControl();
    const map = useMap();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="primary" enableColorOnDark>
                <Toolbar>
                    <Typography
                        variant="h6"
                        mr={2}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            setOverviewState(OVERVIEW_STATES.CLOSED);
                            setSelectedProduct(null);
                            map.setLayerData(MAP_LAYER_NAMES.MEPAR, {
                                all: false,
                                connecting: true,
                                codes: [],
                                isFrostIndexShown: false,
                            });
                            map.setLayerData(MAP_LAYER_NAMES.FIELDS, {
                                all: false,
                                connecting: true,
                                active: false,
                                ids: [],
                            });
                            navigate('/');
                        }}
                    >
                        GeoFintech
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {user && (
                        <>
                            <YearSelector />
                            <SearchForm />
                            <Typography variant="h6" mr={2}>
                                {user.name}
                            </Typography>
                            <UserMenu />
                        </>
                    )}
                </Toolbar>
                <NavDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
            </AppBar>
        </Box>
    );
};

export default Navbar;
