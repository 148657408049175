import { format } from 'date-fns';
import { Fill, Style } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import formatErrorMessage from '../../utils/formatErrorMessage';
import { styles } from '../MapWrapperStyles';

export default function DroughtDataprovider({ extent, layerData, setSource }) {
    const { notifyError } = useNotify();
    const getDroughtLayer = useCallback(
        async (controller, params) =>
            api.map.getMapDrought(params, { signal: controller.signal }).then((res) => res.data),
        [api]
    );

    const addStyleToDroughts = (droughtsWithoutStyle) =>
        droughtsWithoutStyle.map((e) => ({
            ...e,
            style: new Style({
                ...styles.drought,
                fill: new Fill({
                    color: e.drought ? [255, 0, 0, 0.1] : [0, 255, 0, 0.1],
                }),
            }),
        }));

    useEffect(() => {
        if (layerData.isDroughtShown === false) {
            setSource(null);
            return;
        }

        const controller = new AbortController();

        getDroughtLayer(controller, {
            extent,
            date: format(new Date(layerData?.droughtDate), 'yyyy-MM-dd'),
        })
            .then((newDroughtLayer) => setSource(addStyleToDroughts(newDroughtLayer)))
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(formatErrorMessage(err));
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getDroughtLayer, extent, layerData, setSource]);

    return null;
}
