/* eslint-disable react/no-array-index-key */
import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useLayout } from '../../../../providers/LayoutContext';
import AccountDetails from './components/AccountDetails';
import AdministrationData from './components/AdministrationData';
import ApplierData from './components/ApplierData';
import CropArchiveData from './components/CropArchiveData';
import CropData from './components/CropData';
import EfaData from './components/EfaData';
import LandscapeValue from './components/LandscapeValue';
import RequestedArea from './components/RequestedArea';
import SupportsData from './components/SupportsData';

const DocumentBlock = () => {
    const { selectedProduct } = useLayout();
    const supportsData = Object.values(selectedProduct.supports).filter((e) => e === true);

    return (
        <Grid
            container
            sx={{
                p: 3,
                height: '100%',
                backgroundColor: '#fff',
                overflow: 'auto',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
            }}
            alignItems="flex-start"
        >
            <Grid item container xs={12} spacing={0} sx={{ display: 'flex' }}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <Typography variant="h5">EK azonosító: {selectedProduct.code}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ width: '100%' }}>
                        {selectedProduct.efa && <EfaData data={selectedProduct.efa} />}

                        {selectedProduct.applicationId && (
                            <AdministrationData
                                data={{
                                    applicationId: selectedProduct.applicationId,
                                    lastSavedAt: selectedProduct.lastSavedAt,
                                    lastModifiedAt: selectedProduct.lastModifiedAt,
                                    appliedAt: selectedProduct.appliedAt,
                                    approvedAt: selectedProduct.approvedAt,
                                    signedAt: selectedProduct.signedAt,
                                }}
                            />
                        )}

                        {selectedProduct.applier && (
                            <ApplierData applierId={selectedProduct.applier.code} />
                        )}

                        {selectedProduct.client && (
                            <AccountDetails clientId={selectedProduct.client.code} />
                        )}

                        {supportsData.length > 0 && (
                            <SupportsData data={{ supports: selectedProduct.supports }} />
                        )}

                        {selectedProduct.cropArchives && (
                            <CropArchiveData
                                data={{
                                    cropArchives: selectedProduct.cropArchives,
                                }}
                            />
                        )}

                        {selectedProduct.crops && (
                            <CropData
                                data={{
                                    crops: selectedProduct.crops,
                                }}
                            />
                        )}

                        {selectedProduct.mepars && (
                            <RequestedArea
                                data={{
                                    mepars: selectedProduct.mepars,
                                }}
                            />
                        )}

                        {selectedProduct.landscapeValue && (
                            <LandscapeValue data={selectedProduct} />
                        )}
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DocumentBlock;
