import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';

const AdministrationData = ({ data }) => {
    return (
        <Accordion elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography variant="h6">Adminisztrációs adatok</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Iratazonosító: </TableCell>
                            <TableCell>{data.applicationId ?? '-'}</TableCell>
                        </TableRow>
                        {data.lastSavedAt && (
                            <TableRow>
                                <TableCell>Utolsó mentés dátuma: </TableCell>
                                <TableCell>
                                    {format(new Date(data.lastSavedAt), 'yyyy.MM.dd. HH:mm:ss')}
                                </TableCell>
                            </TableRow>
                        )}
                        {data.lastModifiedAt && (
                            <TableRow>
                                <TableCell>Utolsó adatmódosítás dátuma: </TableCell>
                                <TableCell>
                                    {format(new Date(data.lastModifiedAt), 'yyyy.MM.dd. HH:mm:ss')}
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell>Elfogadás dátuma: </TableCell>
                            <TableCell>
                                {data.approvedAt
                                    ? format(new Date(data.approvedAt), 'yyyy.MM.dd. HH:mm:ss')
                                    : '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Alkalmazás típusa:</TableCell>
                            <TableCell>-</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Megszemélyesített adatok betöltésének dátuma:</TableCell>
                            <TableCell>-</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Egységes kérelem aláírásának időpontja: </TableCell>
                            <TableCell>
                                {data.signedAt
                                    ? format(new Date(data.signedAt), 'yyyy.MM.dd. HH:mm:ss')
                                    : '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Egységes kérelem beadásának időpontja: </TableCell>
                            <TableCell>
                                {data.appliedAt
                                    ? format(new Date(data.appliedAt), 'yyyy.MM.dd. HH:mm:ss')
                                    : '-'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    );
};
export default AdministrationData;
