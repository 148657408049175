import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { Grid, IconButton, Paper } from '@mui/material';
import { MAP_EVENTS, useMap } from 'es-map-widget';
import React, { useEffect, useRef, useState } from 'react';
import { BASE_COLORS } from '../../constants';
import { AlertContextProvider } from './contexts/AlertContext';
import { DroughtContextProvider } from './contexts/DroughtContext';
import { MenuContextProvider } from './contexts/MenuContext';
import DocumentDataController from './maincontrollers/DocumentDataController';
import MonitoringController from './maincontrollers/MonitoringController';
import RiskFactorController from './maincontrollers/RiskFactorController';
import SoilAndTerrainController from './maincontrollers/SoilAndTerrainController';
import YieldController from './maincontrollers/YieldController';

const MapController = () => {
    const map = useMap();
    const menuRef = useRef(null);
    const [menuScroll, setMenuScroll] = useState({ left: 0, leftMax: 0 });

    const [_, setVisibilityMap] = useState({});
    useEffect(() => {
        setVisibilityMap(map.getLayerVisibility());
        const x = map.addEventListener(MAP_EVENTS.visibilityChange, (e) => {
            setVisibilityMap(e.layerVisibility);
        });
        return () => {
            map.removeEventListener(MAP_EVENTS.visibilityChange, x);
        };
    }, [map, setVisibilityMap]);

    return (
        <MenuContextProvider>
            <Paper
                elevation={0}
                square
                sx={{
                    background: BASE_COLORS.PRIMARY_MAIN,
                    color: BASE_COLORS.WHITE,
                    p: 1,
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                }}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <IconButton
                            size="small"
                            disabled={menuScroll.left === 0}
                            onClick={() => {
                                menuRef.current.scrollBy(-110, 0);
                            }}
                            className="map-controller-arrow"
                        >
                            <ChevronLeftRounded sx={{ color: 'white' }} />
                        </IconButton>
                    </Grid>
                    <Grid
                        container
                        item
                        xs
                        alignItems="center"
                        justifyContent="flex-start"
                        sx={{
                            overflowX: 'auto',
                            flexWrap: 'nowrap',
                            '& div': {
                                flexShrink: 0,
                                '&:not(:last-child)': {
                                    marginRight: '15px',
                                },
                            },
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                        ref={menuRef}
                        onScroll={() => {
                            setMenuScroll({
                                left: menuRef.current.scrollLeft,
                                leftMax: menuRef.current.scrollWidth - menuRef.current.clientWidth,
                            });
                        }}
                    >
                        <DocumentDataController />
                        <AlertContextProvider>
                            <MonitoringController />
                        </AlertContextProvider>
                        <DroughtContextProvider>
                            <RiskFactorController />
                        </DroughtContextProvider>
                        <YieldController />
                        <SoilAndTerrainController />
                    </Grid>
                    <Grid item>
                        <IconButton
                            size="small"
                            disabled={menuScroll.left >= menuScroll.leftMax}
                            onClick={() => {
                                menuRef.current.scrollBy(110, 0);
                            }}
                            className="map-controller-arrow"
                        >
                            <ChevronRightRounded sx={{ color: 'white' }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
        </MenuContextProvider>
    );
};

export default MapController;
