/* eslint-disable jsx-a11y/no-static-element-interactions */
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    CircularProgress,
    ClickAwayListener,
    InputBase,
    ListSubheader,
    MenuItem,
    MenuList,
    Paper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OVERVIEW_STATES } from '../../constants';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import { useAppControl } from '../../providers/AppContext';
import { useLayout } from '../../providers/LayoutContext';
import formatErrorMessage from '../../utils/formatErrorMessage';

const menuItems = [
    { id: 'documents', name: 'Dokumentumok' },
    { id: 'clients', name: 'Ügyfelek' },
    { id: 'mepars', name: 'MePAR-ok' },
];

const SearchForm = () => {
    const { notifyError } = useNotify();
    const { year, setOverviewState } = useAppControl();
    const { setSelectedProduct } = useLayout();
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState(false);

    const [result, loading, error] = useApi(
        (api, params) => api.search.searchAll({ search: !value ? undefined : value, year }, params),
        [value, year]
    );

    useEffect(() => {
        if (!error) {
            return;
        }
        notifyError(formatErrorMessage(error));
    }, [error, notifyError]);

    return (
        <ClickAwayListener
            onClickAway={() => {
                setOpen(false);
                setFocused(false);
            }}
        >
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1052,
                    marginRight: '10px',
                    padding: '4px 14px',
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    border: '1px solid #0C173E',
                    borderBottomLeftRadius: !focused && '8px',
                    borderBottomRightRadius: !focused && '8px',
                    borderBottom: focused ? '1px solid #D9E0E5' : '1px solid #0C173E',
                }}
            >
                <SearchIcon
                    sx={{
                        color: 'black',
                    }}
                />
                <InputBase
                    type="text"
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                    onFocus={() => {
                        setOpen(true);
                        setFocused(true);
                    }}
                    placeholder="Keresés..."
                    sx={{
                        '& .MuiInputBase-input': {
                            backgroundColor: '#fff',
                            color: 'black',
                            paddingLeft: '8px',
                        },
                    }}
                />
                {open && (
                    <Paper
                        sx={{
                            display: open && 'block',
                            position: 'absolute',
                            top: 43,
                            left: 0,
                            backgroundColor: '#fff',
                            width: '100%',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                        }}
                    >
                        {loading && (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    py: 1,
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )}
                        {result &&
                            menuItems.map((menuItem) => (
                                <MenuList
                                    key={menuItem.id}
                                    subheader={
                                        <ListSubheader
                                            style={{ lineHeight: '36px', fontWeight: 700 }}
                                            component="div"
                                            id={`${menuItem.id}-subheader`}
                                        >
                                            {menuItem.name}
                                        </ListSubheader>
                                    }
                                    sx={{ zIndex: 1052 }}
                                >
                                    {result[menuItem.id].map((item) => (
                                        <MenuItem
                                            key={`${item.id}-${item.text}`}
                                            onClick={() => {
                                                setOpen(false);
                                                setFocused(false);
                                                setValue('');
                                                setOverviewState(OVERVIEW_STATES.CLOSED);
                                                setSelectedProduct(null);
                                                navigate(`/products/${item.type}/${item.text}`);
                                            }}
                                        >
                                            {item.text}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            ))}
                    </Paper>
                )}
            </div>
        </ClickAwayListener>
    );
};

export default SearchForm;
