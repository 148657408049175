import { CONTROLLER_KEYS, CONTROLLER_LABELS } from './controllerKeysAndLabels';
import { MAP_LAYER_NAMES } from './MapLayerNames';

// TODO: move it to db
const crops = Object.freeze({
    [CONTROLLER_KEYS.SPIKE]: 1,
    [CONTROLLER_KEYS.COLE_SEED]: 2,
    [CONTROLLER_KEYS.CORN]: 3,
    [CONTROLLER_KEYS.SUNFLOWER]: 4,
});

const cropNumbers = Object.freeze({
    1: 'spike',
    2: 'autumnCabbageRape',
    3: 'corn',
    4: 'sunflower',
});

const MAP_KEYS = {
    OSM: MAP_LAYER_NAMES.OSM,
    BING: MAP_LAYER_NAMES.BING,
    ORTHO: CONTROLLER_LABELS.ORTO_PHOTO,
};

export { crops, cropNumbers, MAP_KEYS };
export * from './colors';
export * from './controllerKeysAndLabels';
export * from './overviewStates';
