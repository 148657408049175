import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const DocumentApplicationWrapper = ({ applications }) => {
    return (
        <Grid container item justifyContent="space-between" sx={{ pt: 1, pb: 2 }}>
            <Grid item xs={12}>
                <Typography variant="body1" fontWeight={700} sx={{ mt: 3, mb: 1 }}>
                    Ügyfél által beadott EK-k
                </Typography>
            </Grid>

            {applications.map((application) => {
                return (
                    <Grid container justifyContent="space-between" xs={12} key={application.code}>
                        <Grid item>EK azonosító </Grid>
                        <Grid item>
                            <Link to={`/products/document/${application.code}`}>
                                {application.code}
                            </Link>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default DocumentApplicationWrapper;
