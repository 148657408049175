import { Grid } from '@mui/material';
import React from 'react';
import ContentArea from '../components/ContentArea';

const PageLayout = ({ children }) => {
    return (
        <Grid container spacing={2} sx={{ height: '100%' }}>
            {children}
            <ContentArea />
        </Grid>
    );
};

export default PageLayout;
