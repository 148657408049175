import { React, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

const useNotify = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const closeButton = (key) => (
        <Button color="inherit" onClick={() => closeSnackbar(key)}>
            Bezárás
        </Button>
    );

    const notifySuccess = useCallback(
        (message, persist = false) => {
            enqueueSnackbar(message, {
                variant: 'success',
                persist,
                action: persist ? (key) => closeButton(key) : undefined,
            });
        },
        [enqueueSnackbar, closeSnackbar]
    );
    const notifyInfo = useCallback(
        (message, persist = false) => {
            enqueueSnackbar(message, {
                variant: 'info',
                persist,
                action: persist ? (key) => closeButton(key) : undefined,
            });
        },
        [enqueueSnackbar, closeSnackbar]
    );
    const notifyError = useCallback(
        (message) => {
            enqueueSnackbar(message, {
                variant: 'error',
                persist: true,
                action: (key) => closeButton(key),
            });
        },
        [enqueueSnackbar, closeSnackbar]
    );

    return {
        notifySuccess,
        notifyError,
        notifyInfo,
    };
};

export default useNotify;
