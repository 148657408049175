import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';

const UserMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const { logoutUser } = useAuth();
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                <PersonOutlineRoundedIcon sx={{ color: 'white !important' }} />
            </IconButton>

            <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose()}>
                <MenuItem
                    onClick={() => {
                        navigate('/profile');
                        handleClose();
                    }}
                >
                    Profilom
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        logoutUser();
                        navigate('/');
                        handleClose();
                    }}
                >
                    Kijelentkezés
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserMenu;
