import { ExpandMore, Square } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useMap } from 'es-map-widget';
import { groupBy, sum } from 'ramda';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { categoryColors, OVERVIEW_STATES } from '../../../constants';
import { useApi } from '../../../hooks/useApi';
import { useAppControl } from '../../../providers/AppContext';
import { useLayout } from '../../../providers/LayoutContext';

const FieldBlock = () => {
    const { selectedProduct, setSelectedProduct } = useLayout();
    const { year, setOverviewState } = useAppControl();
    const map = useMap();
    const [expanded, setExpanded] = useState(['yield']);

    const handleChange = (panel) => () => {
        const previousState = [...expanded];
        if (expanded.includes(panel)) {
            previousState.splice(
                expanded.findIndex((e) => e === panel),
                1
            );
            setExpanded(previousState);
        } else {
            setExpanded([...previousState, panel]);
        }
    };

    const handleClose = () => {
        setOverviewState(OVERVIEW_STATES.CLOSED);
        setSelectedProduct(null);
        map.setSelected(null);
    };

    const [zoneResult, zoneLoading, zoneError] = useApi(
        (api, params) => api.fields.getFieldZones({ id: selectedProduct.code, year }, params),
        [selectedProduct.code, year],
        (res) => {
            const zones = groupBy((x) => x.zone, res);
            return {
                yieldTotal: sum(res.map(({ yieldSum }) => yieldSum)),
                yieldByZone: Object.entries(zones).map(([zone, zoneData]) => [
                    zone,
                    sum(zoneData.map(({ yieldPerArea }) => yieldPerArea)) / zoneData.length,
                ]),
            };
        }
    );

    const [result, loading, error] = useApi(
        (api, params) => api.fields.getField({ id: selectedProduct.code, year }, params),
        [selectedProduct.code, year]
    );

    return (
        <Grid
            container
            sx={{
                p: 3,
                height: '100%',
                backgroundColor: '#fff',
                overflow: 'auto',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
            }}
            alignItems="flex-start"
        >
            {error && (
                <Grid item xs={12}>
                    <Alert severity="error">Nem található adat a táblához {year}-ben.</Alert>
                </Grid>
            )}
            {loading && (
                <Grid container item xs={12} justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Grid>
            )}
            {result && !error && (
                <>
                    <Grid container direction="column" alignItems="flex-end">
                        <Grid item sx={{ mb: 1 }}>
                            <Link
                                to={`/products/client/${result.document.client.code}`}
                                onClick={handleClose}
                            >
                                Ügyfél: {result.document.client.code}
                            </Link>
                        </Grid>
                        <Grid item sx={{ mb: 1 }}>
                            <Link
                                to={`/products/document/${result.document.code}`}
                                onClick={handleClose}
                            >
                                EK: {result.document.code}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to={`/products/mepar/${result.mepar.code}`} onClick={handleClose}>
                                MePAR: {result.mepar.code}
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Tábla sorszáma:</TableCell>
                                    <TableCell>{result.fieldNumber}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: '50%' }}>Növény:</TableCell>
                                    <TableCell> {result.mainPlantName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: '50%' }}>Hasznosítási kód:</TableCell>
                                    <TableCell>{result.mainPlantCode}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Berajzolt terület: </TableCell>
                                    <TableCell>{result.areaDrawn} </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: '50%' }}>Igényelt terület:</TableCell>
                                    <TableCell>{result.areaRequested}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ width: '50%' }}>
                                        Terület mértékegység:
                                    </TableCell>
                                    <TableCell>{result.areaType}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </>
            )}
            {zoneError && (
                <Grid item xs={12}>
                    <Alert severity="info">
                        Ezen a táblán nincs elérhető előzetes hozamszámítás.
                    </Alert>
                </Grid>
            )}
            {zoneLoading && (
                <Grid container item xs={12} justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Grid>
            )}
            {zoneResult && !zoneError && (
                <Grid item xs={12}>
                    <div style={{ width: '100%' }}>
                        <Accordion
                            expanded={expanded.includes('yield')}
                            onChange={handleChange('yield')}
                            elevation={0}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography variant="h6">Előzetes hozamszámítás</Typography>
                            </AccordionSummary>
                            {!zoneLoading && zoneResult && (
                                <AccordionSummary>
                                    <Typography>
                                        {`Tábla össz. hozam: 
                                        ${zoneResult.yieldTotal.toFixed(1)}
                                         t`}
                                    </Typography>
                                </AccordionSummary>
                            )}
                            <AccordionDetails>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ZÓNA</TableCell>
                                            <TableCell>JELLEMZŐ HOZAM</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {zoneResult.yieldByZone.map(([zone, avg]) => (
                                            <TableRow key={zone}>
                                                <TableCell
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'no-wrap',
                                                    }}
                                                >
                                                    <Square
                                                        style={{
                                                            color: categoryColors[zone],
                                                        }}
                                                    />
                                                    <span>Zóna {parseInt(zone, 10) + 1}</span>
                                                </TableCell>
                                                <TableCell>{avg.toFixed(1)} t/ha</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export default FieldBlock;
