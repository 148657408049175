import { Grid, Paper } from '@mui/material';
import React from 'react';
import LoginForm from './components/LoginForm';

const LoginPage = () => {
    return (
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item xs={6}>
                <Paper>
                    <LoginForm />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default LoginPage;
