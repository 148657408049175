import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { Alert, Grid, IconButton, Paper } from '@mui/material';
import { MAP_EVENTS, useMap } from 'es-map-widget';
import React, { useEffect, useRef, useState } from 'react';
import { MAP_LAYER_NAMES } from '../../constants/MapLayerNames';
import Legend from './components/Legend';

const excludedMaps = [
    MAP_LAYER_NAMES.BING,
    MAP_LAYER_NAMES.OSM,
    MAP_LAYER_NAMES.MEPAR,
    MAP_LAYER_NAMES.FIELDS,
    MAP_LAYER_NAMES.TERRAIN_MODEL,
    MAP_LAYER_NAMES.ORTHO_PHOTO,
    MAP_LAYER_NAMES.ALERT_AREA,
    MAP_LAYER_NAMES.ALERT_WMS,
];

const MapLegend = ({ bottom }) => {
    const map = useMap();
    const [layerVisibility, setVisibilityMap] = useState(
        Object.entries(map.getLayerVisibility())
            .filter(([layerName, visible]) => visible && !excludedMaps.includes(layerName))
            .map(([layerName, _]) => layerName)
    );
    const legendRef = useRef(null);
    const [legendScroll, setLegendScroll] = useState({ left: 0, leftMax: 0 });

    useEffect(() => {
        setVisibilityMap(
            Object.entries(map.getLayerVisibility())
                .filter(([layerName, visible]) => visible && !excludedMaps.includes(layerName))
                .map(([layerName, _]) => layerName)
        );
        const x = map.addEventListener(MAP_EVENTS.visibilityChange, (e) => {
            setVisibilityMap(
                Object.entries(e.layerVisibility)
                    .filter(([layerName, visible]) => visible && !excludedMaps.includes(layerName))
                    .map(([layerName, _]) => layerName)
            );
        });
        return () => {
            map.removeEventListener(MAP_EVENTS.visibilityChange, x);
        };
    }, [map, setVisibilityMap]);

    return (
        <Paper
            elevation={2}
            sx={{
                position: 'absolute',
                bottom: bottom + 56,
                left: 32,
                backgroundColor: '#fff',
                zIndex: 100,
                borderRadius: '10px',
                p: 0.625,
                maxWidth: '856px',
            }}
        >
            <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                    <IconButton
                        disabled={layerVisibility.length < 4 || legendScroll.left === 0}
                        onClick={() => {
                            legendRef.current.scrollBy(-210, 0);
                        }}
                    >
                        <ChevronLeftRounded />
                    </IconButton>
                </Grid>
                <Grid
                    container
                    item
                    wrap="nowrap"
                    sx={{
                        overflow: 'auto hidden',
                    }}
                    ref={legendRef}
                    onScroll={() => {
                        setLegendScroll({
                            left: legendRef.current.scrollLeft,
                            leftMax: legendRef.current.scrollWidth - legendRef.current.clientWidth,
                        });
                    }}
                >
                    {layerVisibility.length === 0 && (
                        <Grid item sx={{ p: 1 }}>
                            <Alert severity="warning">Nincs réteg kiválasztva a térképen</Alert>
                        </Grid>
                    )}
                    {layerVisibility.map((e) => (
                        <Legend mapName={e} key={e} />
                    ))}
                </Grid>
                <Grid item>
                    <IconButton
                        disabled={
                            layerVisibility.length < 4 || legendScroll.left >= legendScroll.leftMax
                        }
                        onClick={() => {
                            legendRef.current.scrollBy(210, 0);
                        }}
                    >
                        <ChevronRightRounded />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MapLegend;
