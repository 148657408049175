import { FormControl, FormGroup, Grid, RadioGroup } from '@mui/material';
import React from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { useMenu } from '../contexts/MenuContext';
import SubMenuRadio from '../menu/SubMenuRadio';

const YieldDataController = ({ value, setValue, defaultValue, isLayerVisible, menuKey }) => {
    const { handleRadioItemCheck } = useMenu();
    return (
        <Grid container justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
                <FormGroup>
                    <FormControl>
                        <RadioGroup
                            defaultValue={defaultValue}
                            name="map-controller-radio-group"
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                        >
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.RAPE_SEED}
                                label={CONTROLLER_LABELS.RAPE_SEED}
                                disabled={!isLayerVisible}
                                onChange={() =>
                                    handleRadioItemCheck(CONTROLLER_KEYS.RAPE_SEED, menuKey)
                                }
                            />
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.WHEAT}
                                label={CONTROLLER_LABELS.WHEAT}
                                disabled={!isLayerVisible}
                                onChange={() =>
                                    handleRadioItemCheck(CONTROLLER_KEYS.WHEAT, menuKey)
                                }
                            />
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.CORN}
                                label={CONTROLLER_LABELS.CORN}
                                disabled={!isLayerVisible}
                                onChange={() => handleRadioItemCheck(CONTROLLER_KEYS.CORN, menuKey)}
                            />
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.SUNFLOWER}
                                label={CONTROLLER_LABELS.SUNFLOWER}
                                disabled={!isLayerVisible}
                                onChange={() =>
                                    handleRadioItemCheck(CONTROLLER_KEYS.SUNFLOWER, menuKey)
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </FormGroup>
            </Grid>
        </Grid>
    );
};

export default YieldDataController;
