import { Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavDrawer = ({ isDrawerOpen, setIsDrawerOpen }) => {
    const navigate = useNavigate();
    const handleClose = () => {
        setIsDrawerOpen(false);
    };
    const drawerMenuItems = [
        {
            key: 'home',
            name: 'Kezdőlap',
            to: '/',
        },
        {
            key: 'profile',
            name: 'Profilom',
            to: '/profile',
        },
    ];

    return (
        <Drawer open={isDrawerOpen} onClose={handleClose}>
            <List sx={{ width: '200px' }}>
                {drawerMenuItems.map((item) => (
                    <ListItem
                        disablePadding
                        key={item.key}
                        onClick={() => {
                            navigate(item.to);
                            handleClose();
                        }}
                    >
                        <ListItemButton>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

export default NavDrawer;
