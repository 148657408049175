import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './layouts/Layout';
import PageLayout from './layouts/PageLayout';
import ClientPage from './pages/ClientPage/ClientPage';
import DocumentPage from './pages/DocumentPage/DocumentPage';
import Home from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import MeparPage from './pages/MeparPage/MeparPage';
import NotFound from './pages/NotFoundPage/NotFoundPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import { useAuth } from './providers/AuthProvider';

function ProtectedPage({ children }) {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/login" />;
    }

    return children;
}

const App = () => {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route
                    path="/"
                    element={
                        <ProtectedPage>
                            <PageLayout>
                                <Home />
                            </PageLayout>
                        </ProtectedPage>
                    }
                />
                <Route
                    path="profile"
                    element={
                        <ProtectedPage>
                            <ProfilePage />
                        </ProtectedPage>
                    }
                />
                <Route
                    path="/products/document/:id"
                    element={
                        <ProtectedPage>
                            <PageLayout>
                                <DocumentPage />
                            </PageLayout>
                        </ProtectedPage>
                    }
                />
                <Route
                    path="/products/mepar/:id"
                    element={
                        <ProtectedPage>
                            <PageLayout>
                                <MeparPage />
                            </PageLayout>
                        </ProtectedPage>
                    }
                />
                <Route
                    path="/products/client/:id"
                    element={
                        <ProtectedPage>
                            <PageLayout>
                                <ClientPage />
                            </PageLayout>
                        </ProtectedPage>
                    }
                />
                <Route path="login" element={<LoginPage />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
};

export default App;
