import { getDate, getMonth } from 'date-fns';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PRODUCT_CONTROLLER_STATE } from '../../../config';
import { CONTROLLER_KEYS } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';

const MenuContext = createContext();
MenuContext.displayName = 'MenuContext';

const defaultMenuState = {
    [CONTROLLER_KEYS.MONITORING]: [],
    [CONTROLLER_KEYS.CROP]: [],
    [CONTROLLER_KEYS.RISK_FACTOR]: [],
    [CONTROLLER_KEYS.YIELD_PREDICTIVE]: '',
    [CONTROLLER_KEYS.YIELD_MAP]: '',
    [CONTROLLER_KEYS.SOIL_AND_TERRAIN]: [],
    [CONTROLLER_KEYS.TABLE]: [CONTROLLER_KEYS.TABLE_CONNECTING],
    [CONTROLLER_KEYS.MEPAR]: [CONTROLLER_KEYS.MEPAR_CONNECTING],
};

function getStateFromStorage() {
    const item = localStorage.getItem(PRODUCT_CONTROLLER_STATE);
    const isExists = !!item && item !== 'undefined';
    if (!isExists) {
        localStorage.setItem(PRODUCT_CONTROLLER_STATE, JSON.stringify(defaultMenuState));
        return false;
    }
    try {
        return JSON.parse(localStorage.getItem(PRODUCT_CONTROLLER_STATE));
    } catch (err) {
        console.error(err);
        return false;
    }
}

const today = new Date();

export function MenuContextProvider({ children }) {
    const { year } = useAppControl();
    const [checkedItems, setCheckedItemsInternal] = useState(
        () => getStateFromStorage() || defaultMenuState
    );
    const [menuOpen, setMenuOpen] = useState(false);
    const [meparData, setMeparData] = useState({
        all: false,
        connecting: true,
    });

    const [fieldData, setFieldData] = useState({
        all: false,
        connecting: true,
    });

    const [harvestMonitoringData, setHarvestMonitoringData] = useState({
        isLayerShown: false,
        date: new Date(year, getMonth(today), getDate(today)),
    });

    const setCheckedItems = useCallback(
        (newMapController) => {
            if (newMapController === null) {
                localStorage.setItem(PRODUCT_CONTROLLER_STATE, JSON.stringify(defaultMenuState));
                setCheckedItemsInternal(defaultMenuState);
                return;
            }
            localStorage.setItem(PRODUCT_CONTROLLER_STATE, JSON.stringify(newMapController));
            setCheckedItemsInternal(newMapController);
        },
        [setCheckedItemsInternal]
    );

    const handleItemCheck = (item, key) => {
        setCheckedItems((prevItems) => {
            const isItemPresent = prevItems[key]?.includes(item);
            const newItems = {
                ...prevItems,
                [key]: isItemPresent
                    ? prevItems[key].filter((e) => e !== item)
                    : [...prevItems[key], item],
            };
            setCheckedItems(newItems);
            return newItems;
        });
    };

    const handleRadioItemCheck = (item, key) => {
        setCheckedItems((prevItems) => {
            const isItemPresent = prevItems[key] && prevItems[key] === item;
            const newItems = {
                ...prevItems,
                [key]: isItemPresent ? '' : item,
            };
            setCheckedItems(newItems);
            return newItems;
        });
    };

    useEffect(() => {
        const newState = getStateFromStorage();
        if (checkedItems === newState) {
            return;
        }
        if (checkedItems === null) {
            setCheckedItems(null);
            return;
        }
        setCheckedItems(checkedItems);
    }, [checkedItems, setCheckedItems]);

    const contextValue = useMemo(
        () => ({
            menuOpen,
            checkedItems,
            meparData,
            fieldData,
            harvestMonitoringData,
            setMenuOpen,
            handleItemCheck,
            setMeparData,
            setFieldData,
            setHarvestMonitoringData,
            handleRadioItemCheck,
        }),
        [
            menuOpen,
            checkedItems,
            meparData,
            fieldData,
            harvestMonitoringData,
            setMenuOpen,
            handleItemCheck,
            setMeparData,
            setFieldData,
            setHarvestMonitoringData,
            handleRadioItemCheck,
        ]
    );

    return <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>;
}

export function useMenu() {
    return useContext(MenuContext);
}
