import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';

const CropData = ({ data }) => {
    return (
        <Accordion elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography variant="h6">
                    Területek összesítése hasznosítási adatok szerint
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        {data.crops &&
                            data.crops.map((item, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <TableRow key={i}>
                                    <TableCell>{item.crop}:</TableCell>
                                    <TableCell>{item.area}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    );
};

export default CropData;
