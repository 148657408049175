import format from 'date-fns/format';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const AlertWmsDataprovider = ({ layerData, setSource }) => {
    const { id } = useParams();
    const { pathname } = useLocation();

    useEffect(() => {
        if (layerData.isAlertShown === false || !pathname.includes('mepar')) {
            setSource(null);
            return;
        }

        setSource({
            url: `/1.0/map/wms/${id}?token=${layerData.token || ''}`,
            params: {
                LAYERS: `${id}:${id}_${layerData.type || 'BMI'}_${format(
                    layerData.date,
                    'yyyyMMdd'
                )}`,
            },
        });

        // eslint-disable-next-line consistent-return
        return () => {};
    }, [layerData.type, layerData.date, pathname, id]);

    // this is a HOC
    return null;
};

export default AlertWmsDataprovider;
