import { getDate, getMonth } from 'date-fns';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useAppControl } from '../../../providers/AppContext';

const AlertContext = createContext();
AlertContext.displayName = 'AlertContext';

const today = new Date();

export function AlertContextProvider({ children }) {
    const { year } = useAppControl();
    const [alertMonth, setAlertMonth] = useState(getMonth(today));
    const [alertData, setAlertData] = useState({
        isVisible: false,
        date: new Date(year, alertMonth, getDate(today)),
        area: false,
        type: 'BMI',
    });

    const contextValue = useMemo(
        () => ({
            alertData,
            alertMonth,
            setAlertData,
            setAlertMonth,
        }),
        [alertData, alertMonth, setAlertData, setAlertMonth]
    );

    return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>;
}

export function useAlertMenu() {
    return useContext(AlertContext);
}
