import { getDate, getMonth } from 'date-fns';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useAppControl } from '../../../providers/AppContext';

const DroughtContext = createContext();
DroughtContext.displayName = 'DroughtContext';

const today = new Date();

export function DroughtContextProvider({ children }) {
    const { year } = useAppControl();
    const [droughtMonth, setDroughtMonth] = useState(getMonth(today));
    const [droughtData, setDroughtData] = useState({
        isDroughtShown: false,
        droughtDate: new Date(year, droughtMonth, getDate(today)),
    });

    const contextValue = useMemo(
        () => ({
            droughtData,
            droughtMonth,
            setDroughtData,
            setDroughtMonth,
        }),
        [droughtData, droughtMonth, setDroughtData, setDroughtMonth]
    );

    return <DroughtContext.Provider value={contextValue}>{children}</DroughtContext.Provider>;
}

export function useDroughtMenu() {
    return useContext(DroughtContext);
}
