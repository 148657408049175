import { MAP_EVENTS, useMap } from 'es-map-widget';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

const LayoutContext = createContext();
LayoutContext.displayName = 'LayoutContext';

export function LayoutContextProvider({ children }) {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const map = useMap();

    useEffect(() => {
        if (!map) {
            return;
        }
        const selectionListener = map.addEventListener(MAP_EVENTS.selection, (e) => {
            setSelectedProduct(e.selected);
        });

        // eslint-disable-next-line consistent-return
        return () => {
            map.removeEventListener(MAP_EVENTS.selection, selectionListener);
        };
    }, [map, setSelectedProduct]);

    const contextValue = useMemo(
        () => ({
            selectedProduct,
            setSelectedProduct,
        }),
        [selectedProduct, setSelectedProduct]
    );

    return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
}

export function useLayout() {
    return useContext(LayoutContext);
}
