import { Button, ClickAwayListener, Paper, Popper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMenu } from '../contexts/MenuContext';

const getClassName = (checkedItems, menuKey) => {
    if (!Array.isArray(menuKey)) {
        return checkedItems[menuKey]?.length > 0 ? 'has-items-checked' : '';
    }
    return menuKey.some((key) => checkedItems[key]?.length > 0) ? 'has-items-checked' : '';
};

const MapControllerButton = ({ label, menuKey, children }) => {
    const { checkedItems, setMenuOpen } = useMenu();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [className, setClassName] = useState('');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        setClassName(getClassName(checkedItems, menuKey));
    }, [checkedItems, menuKey]);

    useEffect(() => {
        setMenuOpen(open);
    }, [open, setMenuOpen]);

    return (
        <div>
            <Button
                variant="contained"
                onClick={handleClick}
                disableRipple
                disableTouchRipple
                disableFocusRipple
                className="map-controller-button"
            >
                <span className={className}>{label}</span>
            </Button>
            <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper className="map-controller-dropdown">{children}</Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    );
};

export default MapControllerButton;
