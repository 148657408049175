/**
 *
 */
// eslint-disable-next-line import/prefer-default-export
export const MAP_LAYER_NAMES = {
    OSM: 'OSM',
    BING: 'BING',
    MEPAR: 'MEPAR',
    FIELDS: 'FIELDS',
    ZONE: 'ZONE',
    FROST: 'FROST',
    CROP: 'CROP',
    DROUGHT: 'DROUGHT',
    WATER_LOGGING: 'WATER_LOGGING',
    ORTHO_PHOTO: 'ORTHO_PHOTO',
    TERRAIN_MODEL: 'TERRAIN_MODEL',
    EROSION: 'EROSION',
    YIELD_MAP_CORN: 'YIELD_MAP_CORN',
    YIELD_MAP_CORN_PREDICTIVE: 'YIELD_MAP_CORN_PREDICTIVE',
    YIELD_MAP_SUNFLOWER: 'YIELD_MAP_SUNFLOWER',
    YIELD_MAP_SUNFLOWER_PREDICTIVE: 'YIELD_MAP_SUNFLOWER_PREDICTIVE',
    YIELD_MAP_RAPESEED: 'YIELD_MAP_RAPESEED',
    YIELD_MAP_RAPESEED_PREDICTIVE: 'YIELD_MAP_RAPESEED_PREDICTIVE',
    YIELD_MAP_WHEAT: 'YIELD_MAP_WHEAT',
    YIELD_MAP_WHEAT_PREDICTIVE: 'YIELD_MAP_WHEAT_PREDICTIVE',
    SOIL_MAP: 'SOIL_MAP',
    ALERT_WMS: 'ALERT_WMS',
    ALERT_AREA: 'ALERT_AREA',
    AREA_RATING: 'AREA_RATING',
    HARVEST_MONITORING: 'HARVEST_MONITORING',
};
