import { Grid } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useState } from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/MapLayerNames';
import { useMenu } from '../contexts/MenuContext';
import MapControllerButton from '../menu/MapControllerButton';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';

const SoilAndTerrainController = () => {
    const map = useMap();
    const { checkedItems, handleItemCheck } = useMenu();
    const [isMicroTerrainLayerVisible, setIsMicroTerrainLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.SOIL_AND_TERRAIN].includes(CONTROLLER_KEYS.MICRO_TERRAIN)
    );
    const [isSoilMapLayerVisible, setIsSoilMapLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.SOIL_AND_TERRAIN].includes(CONTROLLER_KEYS.SOIL_MAP)
    );
    const [isErosionMapLayerVisible, setIsErosionMapLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.SOIL_AND_TERRAIN].includes(CONTROLLER_KEYS.EROSION)
    );
    const [isAreaRatingLayerVisible, setIsAreaRatingLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.SOIL_AND_TERRAIN].includes(CONTROLLER_KEYS.AREA_RATING)
    );

    return (
        <MapControllerButton
            label={CONTROLLER_LABELS.SOIL_AND_TERRAIN}
            menuKey={CONTROLLER_KEYS.SOIL_AND_TERRAIN}
        >
            <Grid container>
                <Grid
                    item
                    xs={12}
                    className="dropdown-level-1 no-border"
                    style={{ width: '260px' }}
                >
                    <MapControllerMenuItem
                        text={CONTROLLER_LABELS.MICRO_TERRAIN}
                        checkbox={{
                            checked: isMicroTerrainLayerVisible,
                            onChange: () => {
                                setIsMicroTerrainLayerVisible((pV) => !pV);
                                map.setLayerVisibility(
                                    MAP_LAYER_NAMES.TERRAIN_MODEL,
                                    !isMicroTerrainLayerVisible
                                );
                                handleItemCheck(
                                    CONTROLLER_KEYS.MICRO_TERRAIN,
                                    CONTROLLER_KEYS.SOIL_AND_TERRAIN
                                );
                            },
                        }}
                    />
                    <MapControllerMenuItem
                        text={CONTROLLER_LABELS.SOIL_MAP}
                        checkbox={{
                            checked: isSoilMapLayerVisible,
                            onChange: () => {
                                setIsSoilMapLayerVisible((pV) => !pV);
                                map.setLayerVisibility(
                                    MAP_LAYER_NAMES.SOIL_MAP,
                                    !isSoilMapLayerVisible
                                );
                                handleItemCheck(
                                    CONTROLLER_KEYS.SOIL_MAP,
                                    CONTROLLER_KEYS.SOIL_AND_TERRAIN
                                );
                            },
                        }}
                    />
                    <MapControllerMenuItem
                        text={CONTROLLER_LABELS.EROSION}
                        checkbox={{
                            checked: isErosionMapLayerVisible,
                            onChange: () => {
                                setIsErosionMapLayerVisible((pV) => !pV);
                                map.setLayerVisibility(
                                    MAP_LAYER_NAMES.EROSION,
                                    !isErosionMapLayerVisible
                                );
                                handleItemCheck(
                                    CONTROLLER_KEYS.EROSION,
                                    CONTROLLER_KEYS.SOIL_AND_TERRAIN
                                );
                            },
                        }}
                    />
                    <MapControllerMenuItem
                        text={CONTROLLER_LABELS.AREA_RATING}
                        checkbox={{
                            checked: isAreaRatingLayerVisible,
                            onChange: () => {
                                setIsAreaRatingLayerVisible((pV) => !pV);
                                map.setLayerVisibility(
                                    MAP_LAYER_NAMES.AREA_RATING,
                                    !isAreaRatingLayerVisible
                                );
                                map.setLayerData(MAP_LAYER_NAMES.AREA_RATING, {
                                    isLayerShown: !isAreaRatingLayerVisible,
                                });
                                handleItemCheck(
                                    CONTROLLER_KEYS.AREA_RATING,
                                    CONTROLLER_KEYS.SOIL_AND_TERRAIN
                                );
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </MapControllerButton>
    );
};

export default SoilAndTerrainController;
