import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MapContextProvider } from 'es-map-widget';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import theme from '../theme';
import { AppContextProvider } from './AppContext';
import { AuthContextProvider } from './AuthProvider';
import { LayoutContextProvider } from './LayoutContext';
import VIEW_OPTIONS from '../constants/mapOptions';

const Providers = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <AuthContextProvider>
                <AppContextProvider>
                    <MapContextProvider viewOptions={VIEW_OPTIONS}>
                        <LayoutContextProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
                            </LocalizationProvider>
                        </LayoutContextProvider>
                    </MapContextProvider>
                </AppContextProvider>
            </AuthContextProvider>
        </ThemeProvider>
    );
};

export default Providers;
