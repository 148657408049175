import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMap } from 'es-map-widget';
import SideBar from '../../components/Sidebar';
import { MAP_LAYER_NAMES } from '../../constants/MapLayerNames';
import { useApi } from '../../hooks/useApi';
import { useAppControl } from '../../providers/AppContext';
import generateChartData from '../../utils/chartFunctions';
import { OVERVIEW_STATES } from '../../constants';

const MeparPage = () => {
    const { id } = useParams();
    const map = useMap();
    const { year, setOverviewState } = useAppControl();
    const [pieChartData, setPieChartData] = useState([]);
    const [nodes, setNodes] = useState([]);

    const [result, loading, error] = useApi(
        (api, params) => api.mepars.getMepar({ code: id, year }, params),
        [id, year]
    );

    useEffect(() => {
        if (error) {
            map.setLayerData(MAP_LAYER_NAMES.MEPAR, {
                codes: [],
            });
            map.setLayerData(MAP_LAYER_NAMES.FIELDS, {
                ids: [],
            });
        }
        if (!result) {
            setOverviewState(OVERVIEW_STATES.CLOSED);
            return;
        }

        map.setLayerData(MAP_LAYER_NAMES.MEPAR, {
            codes: [result.code],
        });

        map.setLayerData(MAP_LAYER_NAMES.FIELDS, {
            ids: result.fields.map((field) => field.geoApiId),
        });

        map.setLayerData(MAP_LAYER_NAMES.ZONE, {
            ids: result.fields.map((field) => field.geoApiId),
        });

        setNodes(
            result.clients.map((client) => ({
                code: client.code,
                name: `${client.code} (${client.fields.length} tábla)`,
                children: client.fields.map((f) => ({
                    code: f.geoApiId,
                    fieldNumber: f.fieldNumber,
                    layerName: f.fieldNumber,
                    type: 'field',
                    name: `${f.mainPlantName} (${f.areaDrawn} ha) (Tábla sorszáma: ${f.fieldNumber})`,
                })),
            }))
        );

        setPieChartData(
            generateChartData(
                result.fields.map((e) => ({
                    id: e.id,
                    crop: e.mainPlantName,
                    area: e.areaRequested,
                })),
                (dataToPieChart) =>
                    dataToPieChart.map((item) => ({
                        id: item.id,
                        label: item.crop,
                        value: item.area.toFixed(2),
                    }))
            )
        );
        if (result.geom) {
            map.zoomToGeomsExtent({ geom: result.geom });
        }
    }, [map, result, setNodes, setOverviewState, setPieChartData, generateChartData]);

    return (
        <SideBar
            result={result}
            nodes={nodes}
            pieChartData={pieChartData}
            error={error}
            loading={loading}
            dataType="mepar"
        />
    );
};

export default MeparPage;
