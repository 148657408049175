import { Grid } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useCallback, useEffect, useState } from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/MapLayerNames';
import AlertDataController from '../components/AlertDataController';
import CropDataController from '../components/CropDataController';
import HarvestDataController from '../components/HarvestDataController';
import { useMenu } from '../contexts/MenuContext';
import MapControllerButton from '../menu/MapControllerButton';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';
import SubMenuWrapper from '../menu/SubMenuWrapper';

const allCrops = [
    CONTROLLER_KEYS.SPIKE,
    CONTROLLER_KEYS.CORN,
    CONTROLLER_KEYS.COLE_SEED,
    CONTROLLER_KEYS.SUNFLOWER,
];

const getCropState = (localState) => {
    if (!localState[CONTROLLER_KEYS.CROP] || localState[CONTROLLER_KEYS.CROP].length === 0) {
        return [];
    }
    return localState[CONTROLLER_KEYS.CROP];
};

const MonitoringController = () => {
    const map = useMap();
    const { checkedItems, handleItemCheck } = useMenu();
    const [selectedOption, setSelectedOption] = useState(CONTROLLER_KEYS.CROP);
    const [selectedCrops, setSelectedCropsInternal] = useState(getCropState(checkedItems));

    const [isAlertLayerVisible, setIsAlertLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.MONITORING].includes(CONTROLLER_KEYS.ALERT)
    );
    const [isZoneLayerVisible, setIsZoneLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.MONITORING].includes(CONTROLLER_KEYS.ZONE)
    );
    const [isHarvestMonitoringVisible, setIsHarvestMonitoringVisible] = useState(
        checkedItems[CONTROLLER_KEYS.MONITORING].includes(CONTROLLER_KEYS.HARVEST_MONITORING)
    );

    const setSelectedCrops = useCallback(
        (newCrops) => {
            map.setLayerData(MAP_LAYER_NAMES.CROP, {
                selectedCrops: newCrops,
            });
            map.setLayerVisibility(MAP_LAYER_NAMES.CROP, newCrops.length > 0);
            setSelectedCropsInternal(newCrops);
        },
        [setSelectedCropsInternal]
    );

    return (
        <MapControllerButton
            label={CONTROLLER_LABELS.MONITORING}
            menuKey={[CONTROLLER_KEYS.MONITORING, CONTROLLER_KEYS.CROP]}
        >
            <Grid container>
                <Grid item xs={6} className="dropdown-level-1">
                    <MapControllerMenuItem
                        isActive={selectedOption === CONTROLLER_KEYS.CROP}
                        onClick={() => setSelectedOption(CONTROLLER_KEYS.CROP)}
                        text={CONTROLLER_LABELS.CROP}
                        checkbox={{
                            checked: selectedCrops.length === allCrops.length,
                            indeterminate: selectedCrops.length > 0 && selectedCrops.length < 4,
                            onChange: (event) => {
                                const isChecked = event.currentTarget.checked;
                                if (isChecked) {
                                    setSelectedCrops(allCrops);
                                    allCrops
                                        .filter(
                                            (e) => !checkedItems[CONTROLLER_KEYS.CROP].includes(e)
                                        )
                                        .forEach((c) => handleItemCheck(c, CONTROLLER_KEYS.CROP));
                                } else {
                                    checkedItems[CONTROLLER_KEYS.CROP].forEach((c) =>
                                        handleItemCheck(c, CONTROLLER_KEYS.CROP)
                                    );
                                    setSelectedCrops([]);
                                }
                            },
                        }}
                        hasSubMenu
                    />
                    <MapControllerMenuItem
                        isActive={selectedOption === CONTROLLER_KEYS.ALERT}
                        onClick={() => setSelectedOption(CONTROLLER_KEYS.ALERT)}
                        text={CONTROLLER_LABELS.ALERT}
                        checkbox={{
                            checked: isAlertLayerVisible,
                            onChange: () => {
                                setIsAlertLayerVisible((pV) => !pV);
                                map.setLayerVisibility(
                                    MAP_LAYER_NAMES.ALERT_WMS,
                                    !isAlertLayerVisible
                                );
                                if (!isAlertLayerVisible) {
                                    map.setLayerVisibility(MAP_LAYER_NAMES.ALERT_AREA, false);
                                }
                                handleItemCheck(CONTROLLER_KEYS.ALERT, CONTROLLER_KEYS.MONITORING);
                            },
                        }}
                        hasSubMenu
                    />
                    <MapControllerMenuItem
                        text={CONTROLLER_LABELS.ZONE}
                        checkbox={{
                            checked: isZoneLayerVisible,
                            onChange: () => {
                                setIsZoneLayerVisible((pV) => !pV);
                                map.setLayerVisibility(MAP_LAYER_NAMES.ZONE, !isZoneLayerVisible);
                                map.setLayerData(MAP_LAYER_NAMES.ZONE, {
                                    isLayerShown: !isZoneLayerVisible,
                                });
                                handleItemCheck(CONTROLLER_KEYS.ZONE, CONTROLLER_KEYS.MONITORING);
                            },
                        }}
                    />
                    <MapControllerMenuItem
                        isActive={selectedOption === CONTROLLER_KEYS.HARVEST_MONITORING}
                        onClick={() => setSelectedOption(CONTROLLER_KEYS.HARVEST_MONITORING)}
                        text={CONTROLLER_LABELS.HARVEST_MONITORING}
                        checkbox={{
                            checked: isHarvestMonitoringVisible,
                            onChange: () => {
                                setIsHarvestMonitoringVisible((pV) => !pV);
                                handleItemCheck(
                                    CONTROLLER_KEYS.HARVEST_MONITORING,
                                    CONTROLLER_KEYS.MONITORING
                                );
                            },
                        }}
                        hasSubMenu
                    />
                </Grid>
                <Grid item xs={6} className="dropdown-level-2">
                    <SubMenuWrapper isVisible={selectedOption === CONTROLLER_KEYS.CROP}>
                        <CropDataController
                            selectedCrops={selectedCrops}
                            setSelectedCrops={setSelectedCrops}
                        />
                    </SubMenuWrapper>
                    <SubMenuWrapper isVisible={selectedOption === CONTROLLER_KEYS.ALERT}>
                        <AlertDataController isLayerVisible={isAlertLayerVisible} />
                    </SubMenuWrapper>
                    <SubMenuWrapper
                        isVisible={selectedOption === CONTROLLER_KEYS.HARVEST_MONITORING}
                    >
                        <HarvestDataController isLayerVisible={isHarvestMonitoringVisible} />
                    </SubMenuWrapper>
                </Grid>
            </Grid>
        </MapControllerButton>
    );
};

export default MonitoringController;
