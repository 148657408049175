const BASE_COLORS = {
    BACKGROUND_LIGHTER_GRAY: '#EDF6FF',
    BACKGROUND_LIGHT_GRAY: '#D9E0E5',
    TEXT_BLACK: '#001B33',
    TEXT_BLUE: '#0085FF',
    PRIMARY_MAIN: '#0C173E',
    SECONDARY_MAIN: '#094E41',
    BACKGROUND_DEFAULT: '#e5e5e5',
    WHITE: '#ffffff',
    GRAY: '#949494',
};

const categoryColors = {
    0: '#D7191C',
    1: '#EB7310',
    2: '#DBC810',
    3: '#99E630',
    4: '#00723F',
};

const ratingColors = {
    1: '#d7191c',
    2: '#f07c4a',
    3: '#fec981',
    4: '#ffffc0',
    5: '#c4e687',
    6: '#77c35c',
    7: '#1a9641',
};

export { BASE_COLORS, categoryColors, ratingColors };
