import { Fill, Stroke, Text } from 'es-map-widget';

const textStyle = {
    font: '12px sans-serif',
    fill: new Fill({ color: '#000' }),
    stroke: new Stroke({
        color: '#fff',
        width: 2,
    }),
    text: '',
};

const styles = {
    mepar: {
        stroke: new Stroke({
            color: '#005099',
            width: 5,
        }),
        fill: new Fill({
            color: [255, 0, 0, 0],
        }),
        text: new Text(textStyle),
    },
    field: {
        stroke: new Stroke({
            color: '#0E7462',
            width: 3,
        }),
        fill: new Fill({
            color: [0, 0, 255, 0],
        }),
        text: new Text(textStyle),
    },
    corn: {
        stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 0,
        }),
        fill: new Fill({
            color: [241, 198, 0, 0.5],
        }),
        text: new Text({
            ...textStyle,
            text: 'Kukorica',
        }),
    },
    autumnCabbageRape: {
        stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 0,
        }),
        fill: new Fill({
            color: [118, 234, 182, 0.5],
        }),
        text: new Text({
            ...textStyle,
            text: 'Őszi káposztarepce',
        }),
    },
    spike: {
        stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 0,
        }),
        fill: new Fill({
            color: [147, 234, 118, 0.5],
        }),
        text: new Text({
            ...textStyle,
            text: 'Kalászosok',
        }),
    },
    sunflower: {
        stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 0,
        }),
        fill: new Fill({
            color: [0, 202, 137, 0.5],
        }),
        text: new Text({
            ...textStyle,
            text: 'Napraforgó',
        }),
    },
    selected: {
        stroke: new Stroke({
            color: 'black',
            width: 3,
        }),
        fill: new Fill({
            color: [0, 255, 255, 0.1],
        }),
        text: new Text({ ...textStyle }),
    },
    drought: {
        stroke: new Stroke({
            color: '#ffffff',
            width: 2,
        }),
        fill: new Fill({
            color: [0, 255, 0, 0.15],
        }),
    },
    alert: {
        stroke: new Stroke({
            color: '#a83232',
            width: 2,
        }),
        fill: new Fill({
            color: [0, 0, 0, 0],
        }),
    },
};

export { styles, textStyle };
