import { useMap } from 'es-map-widget';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SideBar from '../../components/Sidebar';
import { OVERVIEW_STATES } from '../../constants';
import { MAP_LAYER_NAMES } from '../../constants/MapLayerNames';
import { useApi } from '../../hooks/useApi';
import { useAppControl } from '../../providers/AppContext';
import { useLayout } from '../../providers/LayoutContext';
import generateChartData from '../../utils/chartFunctions';

const ClientPage = () => {
    const { id } = useParams();
    const map = useMap();
    const { setSelectedProduct } = useLayout();
    const { year, setOverviewState } = useAppControl();
    const [pieChartData, setPieChartData] = useState([]);
    const [nodes, setNodes] = useState([]);

    const [result, loading, error] = useApi(
        (api, params) => api.clients.getClient(id, params),
        [id]
    );

    useEffect(() => {
        if (!result) {
            return;
        }

        setOverviewState(OVERVIEW_STATES.CLOSED);
        setSelectedProduct(null);

        const doc = result.documents.find((e) => e.year === year);

        if (!doc) {
            setNodes([]);
            setPieChartData([]);
            map.setLayerData(MAP_LAYER_NAMES.MEPAR, {
                codes: [],
            });
            map.setLayerData(MAP_LAYER_NAMES.FIELDS, {
                ids: [],
            });
            return;
        }

        map.setLayerData(MAP_LAYER_NAMES.MEPAR, {
            codes: doc.mepars.map((mepar) => mepar.code),
        });
        map.setLayerData(MAP_LAYER_NAMES.FIELDS, {
            ids: doc.mepars.flatMap((mepar) => mepar.fields.map((field) => field.geoApiId)),
        });
        map.setLayerData(MAP_LAYER_NAMES.ZONE, {
            ids: doc.mepars.flatMap((mepar) => mepar.fields.map((field) => field.geoApiId)),
        });

        setNodes(
            doc.mepars.map((mepar) => ({
                ...mepar,
                name: `${mepar.code} (${mepar.fields.length} tábla)`,
                children: mepar.fields.map((f) => ({
                    code: f.geoApiId,
                    fieldNumber: f.fieldNumber,
                    type: 'field',
                    name: `${f.mainPlantName} (${f.areaDrawn} ha) (Tábla sorszáma: ${f.fieldNumber})`,
                    document: {
                        code: doc.code,
                        client: {
                            code: result.code,
                        },
                    },
                })),
            }))
        );

        const data = generateChartData(doc ? doc.crops : [], (e) =>
            e.map((item) => ({
                id: item.crop,
                label: item.crop,
                value: item.area.toFixed(2),
            }))
        );
        setPieChartData(data);
        const clientGeoms = doc.mepars.filter((e) => e.geom).map((e) => ({ geom: e.geom }));
        if (clientGeoms.length > 0) {
            map.zoomToGeomsExtent(clientGeoms);
        }
    }, [result, generateChartData, setPieChartData, setNodes, map, year]);

    return (
        <SideBar
            result={result}
            nodes={nodes}
            pieChartData={pieChartData}
            error={error}
            loading={loading}
            dataType="client"
        />
    );
};

export default ClientPage;
