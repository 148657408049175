import { Grid } from '@mui/material';
import React from 'react';
import { scales, scaleTypes } from '../../../constants/MapLegendNames';

const Legend = ({ mapName }) => {
    if (!scales[mapName]) {
        return null;
    }
    return (
        <Grid
            container
            style={{
                width: '210px',
                minWidth: '210px',
                padding: '0 5px',
            }}
            spacing={0}
        >
            <Grid
                item
                container
                justifyContent="center"
                sx={{ mb: 0.625, fontSize: '10px', textAlign: 'center' }}
            >
                {scales[mapName].name}
            </Grid>
            <Grid item container sx={{ mb: 0.625 }}>
                {scales[mapName].type === scaleTypes.CONTINUOUS &&
                    Array(100)
                        .fill()
                        .map((_, i) => (
                            <span
                                // eslint-disable-next-line react/no-array-index-key
                                key={i}
                                style={{
                                    width: '2px',
                                    height: '15px',
                                    backgroundColor: scales[mapName].scale(i),
                                }}
                            />
                        ))}
                {scales[mapName].type === scaleTypes.DISCRETE &&
                    scales[mapName].scale.map((color) => (
                        <span
                            key={color}
                            style={{
                                width: `${200 / scales[mapName].scale.length}px`,
                                height: '15px',
                                backgroundColor: color,
                            }}
                        />
                    ))}
            </Grid>
            <Grid
                item
                container
                justifyContent="space-between"
                sx={{ fontSize: '10px' }}
                flexWrap="nowrap"
            >
                {scales[mapName].minMax.map((e) => (
                    <span>{e}</span>
                ))}
            </Grid>
        </Grid>
    );
};

export default Legend;
