/**
 * Logged in user key name in local storage
 * @type {string}
 */
export const LOGGED_IN_USER_KEY = 'loggedInUser';

/**
 * Backend url for openapi
 * @type {string}
 */
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || `http://localhost:3001`;

/**
 * Menu state for QoL and persist through sessions
 * @type {stirng}
 */
export const PRODUCT_CONTROLLER_STATE = 'productControllerState';

/**
 * Default map for map component
 */
export const BASE_MAP = 'basemap';
