import { Alert, CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppControl } from '../providers/AppContext';
import DocumentApplicationWrapper from './DocumentApplicationWrapper';
import GeneralDataWrapper from './GeneralDataWrapper';
import LinkAccordion from './LinkAccordion';

const Sidebar = ({ result, nodes, pieChartData, dataType, error, loading }) => {
    const { year } = useAppControl();
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        if (!result || !result.applications) {
            setApplications([]);
            return;
        }
        setApplications(result.applications.filter((e) => e.year === year));
    }, [result, year]);

    if (!loading && error) {
        return (
            <Grid
                container
                item
                xs={12}
                md={3}
                justifyContent="center"
                sx={{ height: '100%', pr: 1 }}
            >
                <Grid item xs={12} sx={{ height: '50%' }}>
                    <Grid item xs={12}>
                        <Alert severity="error">{error.message}</Alert>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    if (loading) {
        <Grid container item xs={12} md={3} justifyContent="center" sx={{ height: '100%', pr: 1 }}>
            <CircularProgress />
        </Grid>;
    }

    return (
        <Grid
            container
            item
            xs={12}
            md={3}
            alignContent="flex-start"
            sx={{ height: '100%', overflowY: 'auto', pr: 1 }}
        >
            <GeneralDataWrapper data={result} pieChartData={pieChartData} dataType={dataType} />
            {applications.length > 0 && <DocumentApplicationWrapper applications={applications} />}
            <LinkAccordion dataType={dataType} nodes={nodes} />
        </Grid>
    );
};

export default Sidebar;
