import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { object, string } from 'yup';
import useNotify from '../../../hooks/useNotify';
import { useAuth } from '../../../providers/AuthProvider';
import { api } from '../../../hooks/useApi';
import formatErrorMessage from '../../../utils/formatErrorMessage';

const usernameValidationSchema = object({
    displayName: string().required('A mező kitöltése kötelező'),
});

const UserChangeForm = () => {
    const { user, setUser } = useAuth();
    const [username, setUsername] = useState(user.name);
    const [loading, setLoading] = useState(false);
    const { notifySuccess, notifyError } = useNotify();

    const initialValues = {
        displayName: username,
    };

    const submitUserChangeForm = () => {
        setLoading(true);
        api.users
            .updateUser({ username })
            .then((res) => {
                const response = res.data;
                setUser({ ...user, name: response.name });
                setLoading(false);
                notifySuccess('Sikeres névváltoztatás!');
            })
            .catch((err) => {
                setLoading(false);
                notifyError(formatErrorMessage(err));
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submitUserChangeForm}
            validationSchema={usernameValidationSchema}
        >
            {({ errors }) => (
                <Form style={{ padding: '16px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Megjelenített név megváltoztatása</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="displayName"
                                label="Megjelenített név"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                                error={errors.displayName}
                                helperText={errors.displayName}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button type="submit" fullWidth variant="contained">
                                {!loading ? (
                                    'Mentés'
                                ) : (
                                    <CircularProgress color="secondary" size={25} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default UserChangeForm;
