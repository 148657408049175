import { FormGroup } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useCallback } from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/MapLayerNames';
import { useMenu } from '../contexts/MenuContext';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';

const MeparDataController = () => {
    const map = useMap();
    const { handleItemCheck, meparData, setMeparData } = useMenu();

    const setMepar = useCallback(
        (newValue) => {
            map.setLayerData(MAP_LAYER_NAMES.MEPAR, newValue);
            setMeparData(newValue);
        },
        [map, setMeparData]
    );

    return (
        <FormGroup>
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.MEPAR_ALL}
                checkbox={{
                    checked: meparData.all,
                    onChange: (event) => {
                        const isChecked = event.target.checked;
                        setMepar({
                            all: true,
                            connecting: false,
                        });
                        if (isChecked) {
                            handleItemCheck(CONTROLLER_KEYS.MEPAR_ALL, CONTROLLER_KEYS.MEPAR);
                            handleItemCheck(
                                CONTROLLER_KEYS.MEPAR_CONNECTING,
                                CONTROLLER_KEYS.MEPAR
                            );
                        }
                    },
                }}
            />
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.MEPAR_CONNECTING}
                checkbox={{
                    checked: meparData.connecting,
                    onChange: (event) => {
                        const isChecked = event.target.checked;
                        setMepar({
                            all: false,
                            connecting: true,
                        });
                        if (isChecked) {
                            handleItemCheck(
                                CONTROLLER_KEYS.MEPAR_CONNECTING,
                                CONTROLLER_KEYS.MEPAR
                            );
                            handleItemCheck(CONTROLLER_KEYS.MEPAR_ALL, CONTROLLER_KEYS.MEPAR);
                        }
                    },
                }}
            />
        </FormGroup>
    );
};

export default MeparDataController;
