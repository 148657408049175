import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';
import FieldDataController from '../components/FieldDataController';
import MapControllerButton from '../menu/MapControllerButton';
import SubMenuWrapper from '../menu/SubMenuWrapper';
import MeparDataController from '../components/MeparDataController';

const DocumentDataController = () => {
    const [isMeparSelected, setIsMeparSelected] = useState(true);

    return (
        <MapControllerButton
            label={CONTROLLER_LABELS.DOCUMENT_DATA}
            menuKey={CONTROLLER_KEYS.DOCUMENT_DATA}
        >
            <Grid container>
                <Grid item xs={6} className="dropdown-level-1">
                    <MapControllerMenuItem
                        isActive={isMeparSelected}
                        onClick={() => setIsMeparSelected(true)}
                        text="Mepar"
                        hasSubMenu
                    />
                    <MapControllerMenuItem
                        isActive={!isMeparSelected}
                        onClick={() => setIsMeparSelected(false)}
                        text="Tábla"
                        hasSubMenu
                    />
                </Grid>
                <Grid item xs={6} className="dropdown-level-2">
                    <SubMenuWrapper isVisible={isMeparSelected}>
                        <MeparDataController />
                    </SubMenuWrapper>
                    <SubMenuWrapper isVisible={!isMeparSelected}>
                        <FieldDataController />
                    </SubMenuWrapper>
                </Grid>
            </Grid>
        </MapControllerButton>
    );
};

export default DocumentDataController;
