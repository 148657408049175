/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { ChevronRightRounded } from '@mui/icons-material';
import { Checkbox, Grid } from '@mui/material';

const MapControllerMenuItem = ({
    text,
    onClick,
    isActive = false,
    checkbox = undefined,
    hasSubMenu = false,
}) => {
    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className="map-controller-submenu-item"
        >
            <Grid item>
                {typeof checkbox !== 'undefined' && Object.keys(checkbox).length > 0 && (
                    <Checkbox
                        size="small"
                        className="map-controller-checkbox"
                        sx={{ pl: 0 }}
                        {...(checkbox ?? {})}
                    />
                )}
                <span
                    style={{
                        cursor: hasSubMenu ? 'pointer' : 'auto',
                        color: 'white',
                        textDecoration: isActive ? 'underline' : 'none',
                    }}
                    onClick={onClick}
                >
                    {text}
                </span>
            </Grid>
            <Grid item>
                {hasSubMenu && (
                    <ChevronRightRounded
                        fontSize="small"
                        onClick={onClick}
                        sx={{ cursor: 'pointer' }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default MapControllerMenuItem;
