import { Grid } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useCallback, useState } from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/MapLayerNames';
import YieldDataController from '../components/YieldDataController';
import { useMenu } from '../contexts/MenuContext';
import MapControllerButton from '../menu/MapControllerButton';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';
import SubMenuWrapper from '../menu/SubMenuWrapper';

const yieldDefault = CONTROLLER_KEYS.RAPE_SEED;

const yieldArray = [
    CONTROLLER_KEYS.RAPE_SEED,
    CONTROLLER_KEYS.WHEAT,
    CONTROLLER_KEYS.CORN,
    CONTROLLER_KEYS.SUNFLOWER,
];

const yieldMapNames = {
    [CONTROLLER_KEYS.RAPE_SEED]: MAP_LAYER_NAMES.YIELD_MAP_RAPESEED,
    [CONTROLLER_KEYS.WHEAT]: MAP_LAYER_NAMES.YIELD_MAP_WHEAT,
    [CONTROLLER_KEYS.CORN]: MAP_LAYER_NAMES.YIELD_MAP_CORN,
    [CONTROLLER_KEYS.SUNFLOWER]: MAP_LAYER_NAMES.YIELD_MAP_SUNFLOWER,
};

const predictionMapNames = {
    [CONTROLLER_KEYS.RAPE_SEED]: MAP_LAYER_NAMES.YIELD_MAP_RAPESEED_PREDICTIVE,
    [CONTROLLER_KEYS.WHEAT]: MAP_LAYER_NAMES.YIELD_MAP_WHEAT_PREDICTIVE,
    [CONTROLLER_KEYS.CORN]: MAP_LAYER_NAMES.YIELD_MAP_CORN_PREDICTIVE,
    [CONTROLLER_KEYS.SUNFLOWER]: MAP_LAYER_NAMES.YIELD_MAP_SUNFLOWER_PREDICTIVE,
};

const YieldController = () => {
    const map = useMap();
    const { checkedItems, handleRadioItemCheck } = useMenu();
    const [isYieldMapLayerVisible, setIsYieldMapLayerVisible] = useState(
        !!checkedItems[CONTROLLER_KEYS.YIELD_MAP] &&
            yieldArray.includes(checkedItems[CONTROLLER_KEYS.YIELD_MAP])
    );
    const [isPredictionMapLayerVisible, setIsPredictionMapLayerVisible] = useState(
        !!checkedItems[CONTROLLER_KEYS.YIELD_PREDICTIVE] &&
            yieldArray.includes(checkedItems[CONTROLLER_KEYS.YIELD_PREDICTIVE])
    );
    const [selectedOption, setSelectedOption] = useState(
        // eslint-disable-next-line no-nested-ternary
        checkedItems[CONTROLLER_KEYS.YIELD_MAP]
            ? CONTROLLER_KEYS.YIELD_MAP
            : checkedItems[CONTROLLER_KEYS.YIELD_PREDICTIVE]
            ? CONTROLLER_KEYS.YIELD_PREDICTIVE
            : CONTROLLER_KEYS.YIELD_MAP
    );
    const [yieldOption, setYieldOption] = useState(yieldDefault);
    const [predictionOption, setPredictionOption] = useState(yieldDefault);

    const setYieldMap = useCallback(
        (value) =>
            setYieldOption((pV) => {
                map.setLayerVisibility(yieldMapNames[pV], false);
                map.setLayerVisibility(yieldMapNames[value], true);
                return value;
            }),
        [map, setYieldOption]
    );
    const setPredictionMap = useCallback(
        (value) =>
            setPredictionOption((pV) => {
                map.setLayerVisibility(predictionMapNames[pV], false);
                map.setLayerVisibility(predictionMapNames[value], true);
                return value;
            }),
        [map, setYieldOption]
    );

    return (
        <MapControllerButton
            label={CONTROLLER_LABELS.YIELD_MAP}
            menuKey={[CONTROLLER_KEYS.YIELD_MAP, CONTROLLER_KEYS.YIELD_PREDICTIVE]}
        >
            <Grid container>
                <Grid item xs={6} className="dropdown-level-1">
                    <MapControllerMenuItem
                        onClick={() => setSelectedOption(CONTROLLER_KEYS.YIELD_MAP)}
                        text={CONTROLLER_LABELS.YIELD_MAP}
                        checkbox={{
                            checked: isYieldMapLayerVisible,
                            onChange: () => {
                                setIsYieldMapLayerVisible((pV) => !pV);
                                if (!isYieldMapLayerVisible) {
                                    map.setLayerVisibility(yieldMapNames[yieldOption], true);
                                    handleRadioItemCheck(yieldOption, CONTROLLER_KEYS.YIELD_MAP);
                                } else {
                                    map.setLayerVisibility(
                                        yieldMapNames[yieldOption],
                                        !isYieldMapLayerVisible
                                    );
                                    handleRadioItemCheck('', CONTROLLER_KEYS.YIELD_MAP);
                                }
                            },
                        }}
                        hasSubMenu
                        isActive={selectedOption === CONTROLLER_KEYS.YIELD_MAP}
                    />
                    <MapControllerMenuItem
                        onClick={() => setSelectedOption(CONTROLLER_KEYS.YIELD_PREDICTIVE)}
                        text={CONTROLLER_LABELS.YIELD_PREDICTIVE}
                        checkbox={{
                            checked: isPredictionMapLayerVisible,
                            onChange: () => {
                                setIsPredictionMapLayerVisible((pV) => !pV);
                                if (!isPredictionMapLayerVisible) {
                                    map.setLayerVisibility(
                                        predictionMapNames[predictionOption],
                                        true
                                    );
                                    handleRadioItemCheck(
                                        predictionOption,
                                        CONTROLLER_KEYS.YIELD_PREDICTIVE
                                    );
                                } else {
                                    map.setLayerVisibility(
                                        predictionMapNames[predictionOption],
                                        !isPredictionMapLayerVisible
                                    );
                                    handleRadioItemCheck('', CONTROLLER_KEYS.YIELD_PREDICTIVE);
                                }
                            },
                        }}
                        hasSubMenu
                        isActive={selectedOption === CONTROLLER_KEYS.YIELD_PREDICTIVE}
                    />
                </Grid>
                <Grid item xs={6} className="dropdown-level-2">
                    <SubMenuWrapper isVisible={selectedOption === CONTROLLER_KEYS.YIELD_MAP}>
                        <YieldDataController
                            value={yieldOption}
                            setValue={setYieldMap}
                            defaultValue={yieldDefault}
                            isLayerVisible={isYieldMapLayerVisible}
                            menuKey={CONTROLLER_KEYS.YIELD_MAP}
                        />
                    </SubMenuWrapper>
                    <SubMenuWrapper isVisible={selectedOption === CONTROLLER_KEYS.YIELD_PREDICTIVE}>
                        <YieldDataController
                            value={predictionOption}
                            setValue={setPredictionMap}
                            defaultValue={yieldDefault}
                            isLayerVisible={isPredictionMapLayerVisible}
                            menuKey={CONTROLLER_KEYS.YIELD_PREDICTIVE}
                        />
                    </SubMenuWrapper>
                </Grid>
            </Grid>
        </MapControllerButton>
    );
};

export default YieldController;
