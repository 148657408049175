import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { Fragment } from 'react';

const CropArchiveData = ({ data }) => {
    return (
        <Accordion elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography variant="h6">Kárenyhítés/Biztosítási díjtámogatás</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ overflowX: 'auto' }}>
                {data.cropArchives && (
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Hasznosítás</TableCell>
                                {[
                                    ...new Set(
                                        data.cropArchives.reduce(
                                            (prevCrop, currentCrop) => [
                                                ...prevCrop,
                                                ...Object.keys(currentCrop.years),
                                            ],
                                            []
                                        )
                                    ),
                                ].map((year) => (
                                    <Fragment key={year}>
                                        <TableCell>{year} évi terület(ha)</TableCell>
                                        <TableCell>{year} évi termésm(t)</TableCell>
                                    </Fragment>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.cropArchives.map((crop, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <TableRow key={i}>
                                    <TableCell>{crop.crop}</TableCell>
                                    {Object.values(crop.years).map((value, j) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Fragment key={j}>
                                            <TableCell>{value.area.toFixed(2)}</TableCell>
                                            <TableCell>{value.quantity || '-'}</TableCell>
                                        </Fragment>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default CropArchiveData;
