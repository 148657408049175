const calculateColor = (num, percentBase) => {
    const percent = Math.min(1, num / percentBase) * 100;
    let r;
    const b = 0;
    let g;

    if (percent < 50) {
        r = Math.round(5.1 * percent);
        g = 255;
    } else {
        g = Math.round(510 - 5.1 * percent);
        r = 255;
    }

    const h = r * 0x10000 + g * 0x100 + b;
    // eslint-disable-next-line prefer-template
    return `#${('000000' + h.toString(16)).slice(-6)}`;
};

export default calculateColor;
