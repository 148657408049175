import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';

const EfaData = () => {
    return (
        <Accordion elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography variant="h6">Kérelem EFA adatai</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid item xs={6} sx={{ p: 2 }}>
                    ++efa++
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default EfaData;
