import chroma from 'chroma-js';
import { MAP_LAYER_NAMES } from './MapLayerNames';

const scaleTypes = {
    CONTINUOUS: 'CONTINUOUS',
    DISCRETE: 'DISCRETE',
};

const scales = {
    [MAP_LAYER_NAMES.WATER_LOGGING]: {
        name: 'Belvízveszélyeztetettség',
        scale: chroma.scale(['#5cffff', '#0000ff', '#ff0000']).domain([0, 50, 100]),
        minMax: ['0', '50%', '100%'],
        type: scaleTypes.CONTINUOUS,
    },
    [MAP_LAYER_NAMES.DROUGHT]: {
        name: 'Aszályos állapot a 2011. évi CLXVIII. tv. szerint',
        scale: chroma.scale([chroma([255, 0, 0, 0.1]), chroma([0, 255, 0, 0.1])]).colors(2),
        minMax: ['aszály', 'nincs aszály'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.FROST]: {
        name: 'Fagyveszély',
        scale: [
            chroma([69, 102, 49]),
            chroma([138, 204, 98]),
            chroma([220, 240, 158]),
            chroma([255, 223, 154]),
            chroma([246, 144, 83]),
            chroma([215, 25, 28]),
        ],
        minMax: ['0%', '20%', '40%', '60%', '80%', '100%'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.YIELD_MAP_CORN]: {
        name: 'Hozamtérkép kukorica',
        scale: chroma.scale(['red', 'green']).domain([0, 100]),
        minMax: ['1 t/ha', '10< t/ha'],
        type: scaleTypes.CONTINUOUS,
    },
    [MAP_LAYER_NAMES.YIELD_MAP_RAPESEED]: {
        name: 'Hozamtérkép repce',
        scale: chroma.scale(['red', 'green']).domain([0, 100]),
        minMax: ['0.5 t/ha', '6< t/ha'],
        type: scaleTypes.CONTINUOUS,
    },
    [MAP_LAYER_NAMES.YIELD_MAP_SUNFLOWER]: {
        name: 'Hozamtérkép napraforgó',
        scale: chroma.scale(['red', 'green']).domain([0, 100]),
        minMax: ['0.5 t/ha', '6< t/ha'],
        type: scaleTypes.CONTINUOUS,
    },
    [MAP_LAYER_NAMES.YIELD_MAP_WHEAT]: {
        name: 'Hozamtérkép búza',
        scale: chroma.scale(['red', 'green']).domain([0, 100]),
        minMax: ['1 t/ha', '10< t/ha'],
        type: scaleTypes.CONTINUOUS,
    },
    [MAP_LAYER_NAMES.YIELD_MAP_CORN_PREDICTIVE]: {
        name: 'Predikciós hozamtérkép kukorica',
        scale: chroma.scale(['red', 'green']).domain([0, 100]),
        minMax: ['1 t/ha', '10< t/ha'],
        type: scaleTypes.CONTINUOUS,
    },
    [MAP_LAYER_NAMES.YIELD_MAP_RAPESEED_PREDICTIVE]: {
        name: 'Predikciós hozamtérkép repce',
        scale: chroma.scale(['red', 'green']).domain([0, 100]),
        minMax: ['0.5 t/ha', '6< t/ha'],
        type: scaleTypes.CONTINUOUS,
    },
    [MAP_LAYER_NAMES.YIELD_MAP_SUNFLOWER_PREDICTIVE]: {
        name: 'Predikciós hozamtérkép napraforgó',
        scale: chroma.scale(['red', 'green']).domain([0, 100]),
        minMax: ['0.5 t/ha', '6< t/ha'],
        type: scaleTypes.CONTINUOUS,
    },
    [MAP_LAYER_NAMES.YIELD_MAP_WHEAT_PREDICTIVE]: {
        name: 'Predikciós hozamtérkép búza',
        scale: chroma.scale(['red', 'green']).domain([0, 100]),
        minMax: ['1 t/ha', '10< t/ha'],
        type: scaleTypes.CONTINUOUS,
    },
    [MAP_LAYER_NAMES.PREDICTION_MAP_SUNFLOWER]: {
        name: 'Predikciós hozamtérkép',
        scale: chroma.scale(['red', 'green']).domain([0, 100]),
        minMax: ['0 t/ha', '4< t/ha'],
        type: scaleTypes.CONTINUOUS,
    },
    [MAP_LAYER_NAMES.AREA_RATING]: {
        name: 'Területminősítés',
        scale: chroma
            .scale(['#d7191c', '#f07c4a', '#fec981', '#ffffc0', '#c4e687', '#77c35c', '#1a9641'])
            .colors(7),
        minMax: ['alacsony hozamú', 'átlagos', 'magas hozamú'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.SOIL_MAP]: {
        name: 'Humusz tartalom',
        scale: chroma.scale(['red', 'green']).mode('rgb').colors(6),
        minMax: ['kif. alacsony', 'átlagos', 'kif. magas'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.ZONE]: {
        name: 'Relatív biomassza intenzitás  ',
        scale: chroma.scale(['#D7191C', '#EB7310', '#DBC810', '#99E630', '#00723F']).colors(6),
        minMax: ['alacsony', 'magas'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.CROP]: {
        name: 'Vetéstérkép',
        scale: [
            chroma([241, 198, 0, 0.5]),
            chroma([138, 204, 98]),
            chroma([118, 234, 182, 0.5]),
            chroma([0, 202, 137, 0.5]),
        ],
        minMax: ['Kukorica', 'Őszi ká.', 'Kalász.', 'Naprafo.'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.EROSION]: {
        name: 'Erózió - terület lejtés',
        scale: [
            chroma([26, 150, 65]),
            chroma([196, 230, 135]),
            chroma([254, 201, 129]),
            chroma([215, 25, 28]),
        ],
        minMax: ['<2%', '2-6%', '6-12%', '12%<'],
        type: scaleTypes.DISCRETE,
    },
    [MAP_LAYER_NAMES.HARVEST_MONITORING]: {
        name: 'Betakarítás monitoring',
        scale: [chroma([241, 198, 0, 0.5])],
        minMax: ['Betakarított terület'],
        type: scaleTypes.DISCRETE,
    },
};

export { scales, scaleTypes };
