import { Fill, getDefaultViewOptions, Stroke, Style, Text } from 'es-map-widget';

export default {
    viewOptions: { ...getDefaultViewOptions() },
    selectOptions: {
        style(feature) {
            const style = new Style({
                stroke: new Stroke({
                    color: 'black',
                    width: 3,
                }),
                fill: new Fill({
                    color: [0, 255, 255, 0.1],
                }),
                text: new Text({
                    font: '12px sans-serif',
                    fill: new Fill({ color: '#000' }),
                    stroke: new Stroke({
                        color: '#fff',
                        width: 2,
                    }),
                    text: '',
                }),
            });

            const featureProperties = feature.getGeometry().getProperties();

            if (featureProperties.layerName) {
                style.getText().setText(featureProperties.layerName);
            }
            return style;
        },
        filter(feature) {
            const featureProperties = feature.getGeometry().getProperties();
            return featureProperties.type === 'mepar' || featureProperties.type === 'field';
        },
    },
};
