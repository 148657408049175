import { FormControlLabel, Radio } from '@mui/material';
import React from 'react';

const SubMenuRadio = ({ value, label, disabled = false, onChange = () => {} }) => {
    return (
        <FormControlLabel
            value={value}
            label={label}
            control={
                <Radio
                    className="map-controller-radio"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    size="small"
                    onChange={onChange}
                />
            }
            className="map-controller-radio-label"
            disableTypography
            disabled={disabled}
        />
    );
};

export default SubMenuRadio;
