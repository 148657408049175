import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/header/Navbar';

const Layout = () => {
    return (
        <>
            <Navbar />
            <Box bgcolor="#FAFBFD" height="calc(100vh - 144px)" padding={5}>
                <Outlet />
            </Box>
        </>
    );
};

export default Layout;
