import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useLayout } from '../../../providers/LayoutContext';

const ClientBlock = () => {
    const { selectedProduct } = useLayout();
    return (
        <Grid
            container
            sx={{
                p: 3,
                height: '100%',
                backgroundColor: '#fff',
                overflow: 'auto',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
            }}
            alignItems="flex-start"
            alignContent="flex-start"
        >
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Kód:</TableCell>
                        <TableCell>{selectedProduct.code}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Legutóbbi módosítás: </TableCell>
                        <TableCell>
                            {selectedProduct.lastModifiedAt
                                ? format(
                                      new Date(selectedProduct.lastModifiedAt),
                                      'yyyy-MM-dd HH:mm:ss'
                                  )
                                : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Dokumentumok száma:</TableCell>
                        <TableCell>{selectedProduct.documents.length}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Grid>
    );
};

export default ClientBlock;
