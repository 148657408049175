import { format } from 'date-fns';
import { Style } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { api } from '../../hooks/useApi';
import formatErrorMessage from '../../utils/formatErrorMessage';
import { styles } from '../MapWrapperStyles';

export default function AlertAreaDataprovider({ extent, zoom, layerData, setSource }) {
    const { id } = useParams();
    const { pathname } = useLocation();
    const getAlertLayer = useCallback(
        async (controller, params) =>
            api.subscriptions
                .listAlerts(params, { signal: controller.signal })
                .then((res) => res.data),
        [api]
    );

    const addStyleToAlerts = (zonesWithoutStyle) =>
        zonesWithoutStyle.map((e) => ({
            ...e,
            style: new Style({
                ...styles.alert,
            }),
        }));

    useEffect(() => {
        if (layerData.area === false || !pathname.includes('mepar') || zoom < 8.5) {
            setSource(null);
            return;
        }

        const controller = new AbortController();

        getAlertLayer(controller, {
            codes: [id],
            date: layerData.date ? format(layerData.date, 'yyyy-MM-dd') : undefined,
        })
            .then((newAlertLayer) => setSource(addStyleToAlerts(newAlertLayer)))
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    console.error(formatErrorMessage(err));
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getAlertLayer, extent, layerData, id, addStyleToAlerts, setSource]);
    return null;
}
