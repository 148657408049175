import { ChevronRightRounded, CloseRounded } from '@mui/icons-material';
import { Alert, Grid, Paper, Typography } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { OVERVIEW_STATES } from '../constants';
import { MAP_LAYER_NAMES } from '../constants/MapLayerNames';
import { useAppControl } from '../providers/AppContext';
import { useLayout } from '../providers/LayoutContext';
import LinkButton from './atom/LinkButton';
import PieChartWrapper from './PieChartWrapper';

const dataTypeNames = { mepar: 'MePAR', client: 'Ügyfél', document: 'EK' };

const CloseButton = ({ onClick }) => {
    return (
        <LinkButton onClick={onClick} endIcon={<CloseRounded />}>
            Bezárás
        </LinkButton>
    );
};

const ShowOverviewButton = ({ onClick, dataType }) => {
    return (
        <LinkButton onClick={onClick} endIcon={<ChevronRightRounded />}>
            {
                // eslint-disable-next-line no-nested-ternary
                dataType === 'document'
                    ? 'EK adatok'
                    : dataType === 'mepar'
                    ? 'MePAR blokk adatai'
                    : 'Ügyfél adatai'
            }
        </LinkButton>
    );
};

const GeneralDataWrapper = ({ data, dataType, pieChartData }) => {
    const { setSelectedProduct } = useLayout();
    const { year, setOverviewState } = useAppControl();
    const map = useMap();
    const navigate = useNavigate();
    const [doc, setDoc] = useState(null);

    const handleClick = () => {
        setOverviewState(OVERVIEW_STATES.OPEN);
        setSelectedProduct({ ...data, type: dataType });
    };

    const handleClose = useCallback(() => {
        setOverviewState(OVERVIEW_STATES.CLOSED);
        setSelectedProduct(null);
        map.setLayerData(MAP_LAYER_NAMES.MEPAR, {
            all: false,
            connecting: true,
            codes: [],
            isFrostIndexShown: false,
        });
        map.setLayerData(MAP_LAYER_NAMES.FIELDS, {
            all: false,
            connecting: true,
            active: false,
            ids: [],
        });
        map.setLayerData(MAP_LAYER_NAMES.ZONE, {
            ids: [],
        });
        navigate('/');
    }, [map, setOverviewState, setSelectedProduct, navigate]);

    useEffect(() => {
        if (!data?.documents) {
            setDoc(null);
            return;
        }
        const tempDoc = data.documents.find((d) => d.year === year);
        setDoc(tempDoc || null);
    }, [data, setDoc]);

    if (!data) {
        return (
            <Alert severity="info">
                Adatok megjelenítéséhez kezdjen el keresni a keresősávban.
            </Alert>
        );
    }

    if (!doc && dataType === 'client') {
        return (
            <Grid item xs={12} sx={{ overflowX: 'hidden' }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="body1" fontWeight={700} sx={{ mb: 1 }}>
                            {dataTypeNames[dataType]}: {data?.code}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CloseButton onClick={() => handleClose()} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mb: 1 }}>
                    <Alert severity="warning">
                        A megadott évre nincs az ügyfél által benyújtott dokumentum!
                    </Alert>
                </Grid>
                <ShowOverviewButton onClick={() => handleClick()} dataType={dataType} />
            </Grid>
        );
    }

    return (
        <Grid item xs={12} sx={{ overflowX: 'hidden' }}>
            {data?.code && (
                <>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="body1" fontWeight={700} sx={{ mb: 1 }}>
                                {dataTypeNames[dataType]}: {data?.code}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CloseButton onClick={() => handleClose()} />
                        </Grid>
                    </Grid>

                    <Grid container item justifyContent="space-between" sx={{ pt: 1, pb: 2 }}>
                        {dataType === 'client' && data.documents.length > 0 && (
                            <>
                                <Grid item>EK azonosító </Grid>
                                <Grid item>
                                    <Link
                                        to={`/products/document/${data.documents[0].code}`}
                                        onClick={() => setOverviewState(OVERVIEW_STATES.CLOSED)}
                                    >
                                        {data.documents[0].code}
                                    </Link>
                                </Grid>
                            </>
                        )}

                        {dataType === 'document' && (
                            <>
                                <Grid item container justifyContent="space-between" sx={{ pb: 1 }}>
                                    <Grid item>Beadó ügyfélazonosító</Grid>
                                    <Grid item>
                                        <Link
                                            to={`/products/client/${data.applier.code}`}
                                            onClick={() => setOverviewState(OVERVIEW_STATES.CLOSED)}
                                        >
                                            {data.applier.code}
                                        </Link>
                                    </Grid>
                                </Grid>

                                <Grid item container justifyContent="space-between">
                                    <Grid item>Kérelmező ügyfélazonosító</Grid>
                                    <Grid item>
                                        <Link
                                            to={`/products/client/${data.client.code}`}
                                            onClick={() => setOverviewState(OVERVIEW_STATES.CLOSED)}
                                        >
                                            {data.client.code}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </>
            )}

            <Paper sx={{ p: 2, bgcolor: '#FFF' }}>
                {data && (
                    <Grid container rowSpacing={1}>
                        {pieChartData.map((crop, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Grid key={i} container item justifyContent="space-between">
                                <Grid item>{crop.label}</Grid>
                                <Grid item>{crop.value} ha</Grid>
                            </Grid>
                        ))}
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h5">Termések</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ height: '120px', mb: 1 }}>
                                <PieChartWrapper data={pieChartData} />
                            </Grid>
                        </>
                        <Grid item xs={12}>
                            <ShowOverviewButton onClick={() => handleClick()} dataType={dataType} />
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </Grid>
    );
};

export default GeneralDataWrapper;
