import React, { useEffect, useState } from 'react';
import PieChart from './PieChart';

const PieChartWrapper = ({ data }) => {
    const [pieChartMargin, setPieChartMargin] = useState({
        top: 0,
        right: 0,
        bottom: 0,
        left: -200,
    });
    const [pieChartLegendsStyle, setPieChartLegendsStyle] = useState({
        translateX: -50,
        translateY: 0,
        itemsSpacing: 14,
        itemWidth: 150,
        itemHeight: 18,
        symbolSize: 18,
    });

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1699) {
                setPieChartMargin({
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: -150,
                });
                setPieChartLegendsStyle({
                    translateX: 0,
                    translateY: 0,
                    itemsSpacing: 10,
                    itemWidth: 125,
                    itemHeight: 10,
                    symbolSize: 10,
                });
            }
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <PieChart
            data={data}
            margin={pieChartMargin}
            innerRadius={0.8}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            colors={['#063C73', '#00DCCF', '#0085FF', '#A0B1BE']}
            colorBy="index"
            legends={[
                {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000',
                            },
                        },
                    ],
                    ...pieChartLegendsStyle,
                },
            ]}
        />
    );
};

export default PieChartWrapper;
