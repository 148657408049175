const generateChartData = (data, mappingFunction) => {
    if (!data) {
        return [];
    }
    let dataToPieChart = [];
    const chartData = [...data].sort((a, b) => b.area - a.area);

    if (chartData.length <= 3) {
        dataToPieChart = chartData;
    } else {
        const [a, b, c, ...rest] = chartData;
        const summedRest = rest.reduce((item, obj) => item + obj.area, 0);
        dataToPieChart = [a, b, c, { crop: 'Továbbiak', area: summedRest }];
    }

    return mappingFunction(dataToPieChart);
};

export default generateChartData;
