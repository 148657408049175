import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

const ApplierData = ({ applierId }) => {
    return (
        <Accordion elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography variant="h6">Beadó adatai</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        {applierId && (
                            <TableRow>
                                <TableCell>A beadó ügyfél-azonosító száma:</TableCell>
                                <TableCell>
                                    <Link to={`/products/client/${applierId}`}>{applierId}</Link>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    );
};

export default ApplierData;
