import { Style } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { cropNumbers, crops } from '../../constants';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import formatErrorMessage from '../../utils/formatErrorMessage';
import { styles } from '../MapWrapperStyles';

export default function CropDataprovider({ extent, zoom, layerData, setSource }) {
    const { notifyError } = useNotify();

    const getCropMap = useCallback(
        (controller, params) =>
            api.map.getMapCropMap(params, { signal: controller.signal }).then((res) => res.data),
        [api]
    );
    const addStyleToCrops = (cropsWithoutStyle) =>
        cropsWithoutStyle.map((e) => ({
            ...e,
            style: new Style({
                ...styles[cropNumbers[e.crop]],
            }),
        }));

    useEffect(() => {
        if (layerData.selectedCrops.length === 0 || zoom < 8.5) {
            setSource(null);
            return;
        }
        const controller = new AbortController();

        getCropMap(controller, {
            extent,
            types: layerData.selectedCrops.map((e) => crops[e]),
        })
            .then((cropLayers) => setSource(addStyleToCrops(cropLayers)))
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(formatErrorMessage(err));
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getCropMap, layerData, extent, setSource, addStyleToCrops]);
    return null;
}
