/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios from "axios";
export var ContentType;
(function (ContentType) {
  ContentType["Json"] = "application/json";
  ContentType["FormData"] = "multipart/form-data";
  ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
  ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
export class HttpClient {
  instance;
  securityData = null;
  securityWorker;
  secure;
  format;
  constructor({ securityWorker, secure, format, ...axiosConfig } = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "/1.0" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }
  setSecurityData = (data) => {
    this.securityData = data;
  };
  mergeRequestParams(params1, params2) {
    const method = params1.method || (params2 && params2.method);
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase()]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }
  stringifyFormItem(formItem) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }
  createFormData(input) {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent = property instanceof Array ? property : [property];
      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }
      return formData;
    }, new FormData());
  }
  request = async ({ secure, path, type, query, format, body, ...params }) => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;
    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body);
    }
    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }
    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}
/**
 * @title Geofintech API
 * @version 1.0.0
 * @baseUrl /1.0
 */
export class Api extends HttpClient {
  clients = {
    /**
     * No description
     *
     * @tags Clients
     * @name GetClient
     * @summary Get one client with code
     * @request GET:/clients/{code}
     * @secure
     * @response `200` `ClientWithRelations`
     * @response `default` `Error`
     */
    getClient: (code, params = {}) =>
      this.request({
        path: `/clients/${code}`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  documents = {
    /**
     * No description
     *
     * @tags Documents
     * @name GetDocument
     * @summary Get one document with code
     * @request GET:/documents/{code}
     * @secure
     * @response `200` `DocumentWithRelations`
     * @response `default` `Error`
     */
    getDocument: ({ code, ...query }, params = {}) =>
      this.request({
        path: `/documents/${code}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  fields = {
    /**
     * No description
     *
     * @tags Fields
     * @name ListFields
     * @summary List all fields for a certain extent
     * @request GET:/fields
     * @secure
     * @response `200` `(FieldGeometry)[]`
     * @response `default` `Error`
     */
    listFields: (query, params = {}) =>
      this.request({
        path: `/fields`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Fields
     * @name GetField
     * @summary Get one field with code
     * @request GET:/fields/{id}
     * @secure
     * @response `200` `FieldWithRelations`
     * @response `default` `Error`
     */
    getField: ({ id, ...query }, params = {}) =>
      this.request({
        path: `/fields/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags FieldZones
     * @name GetFieldZones
     * @summary Get zones of a field with code
     * @request GET:/fields/{id}/zones
     * @secure
     * @response `200` `(FieldZone)[]`
     * @response `default` `Error`
     */
    getFieldZones: ({ id, ...query }, params = {}) =>
      this.request({
        path: `/fields/${id}/zones`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  mepars = {
    /**
     * No description
     *
     * @tags Mepars
     * @name ListMepars
     * @summary List all Mepars
     * @request GET:/mepars
     * @secure
     * @response `200` `(MeparGeometry)[]`
     * @response `default` `Error`
     */
    listMepars: (query, params = {}) =>
      this.request({
        path: `/mepars`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Mepars
     * @name GetMepar
     * @summary Get one Mepar with code
     * @request GET:/mepars/{code}
     * @secure
     * @response `200` `MeparWithRelations`
     * @response `default` `Error`
     */
    getMepar: ({ code, ...query }, params = {}) =>
      this.request({
        path: `/mepars/${code}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  search = {
    /**
 * No description
 *
 * @tags Search
 * @name SearchAll
 * @summary Search all object with string
 * @request GET:/search/all
 * @secure
 * @response `200` `{
    count: number,
    clients: (SearchResult)[],
    documents: (SearchResult)[],
    mepars: (SearchResult)[],

}`
 * @response `default` `Error`
 */
    searchAll: (query, params = {}) =>
      this.request({
        path: `/search/all`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  subscriptions = {
    /**
     * No description
     *
     * @tags Subscriptions
     * @name ListSubscriptions
     * @summary Get list of subscriptions with MePAR data
     * @request GET:/subscriptions
     * @secure
     * @response `200` `(Subscription)[]`
     * @response `default` `Error`
     */
    listSubscriptions: (params = {}) =>
      this.request({
        path: `/subscriptions`,
        method: "GET",
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Subscriptions
     * @name CreateSubscription
     * @summary Subscribe to a MePAR alerts
     * @request POST:/subscriptions
     * @secure
     * @response `200` `Subscription`
     * @response `default` `Error`
     */
    createSubscription: (data, params = {}) =>
      this.request({
        path: `/subscriptions`,
        method: "POST",
        body: data,
        secure: true,
        ...params,
      }),
    /**
 * No description
 *
 * @tags Subscriptions
 * @name DeleteSubscription
 * @summary Unsubscribe to a MePAR alerts
 * @request DELETE:/subscriptions
 * @secure
 * @response `200` `{
    id: number,

}`
 * @response `default` `Error`
 */
    deleteSubscription: (data, params = {}) =>
      this.request({
        path: `/subscriptions`,
        method: "DELETE",
        body: data,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Subscriptions
     * @name ListAlerts
     * @summary Get list of alerts for a list of MePARs or undefined to list all for the current user
     * @request GET:/subscriptions/alerts
     * @secure
     * @response `200` `(Alert)[]`
     * @response `default` `Error`
     */
    listAlerts: (query, params = {}) =>
      this.request({
        path: `/subscriptions/alerts`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Subscriptions
     * @name CreateAlerts
     * @summary Create a list of alerts for the subscriptions
     * @request POST:/subscriptions/alerts
     * @secure
     * @response `200` `(Alert)[]`
     * @response `default` `Error`
     */
    createAlerts: (data, params = {}) =>
      this.request({
        path: `/subscriptions/alerts`,
        method: "POST",
        body: data,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Subscriptions
     * @name ListSubscriptionMepars
     * @summary Get list of mepars to create alerts for
     * @request GET:/subscriptions/mepars
     * @secure
     * @response `200` `(string)[]`
     * @response `default` `Error`
     */
    listSubscriptionMepars: (params = {}) =>
      this.request({
        path: `/subscriptions/mepars`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name LoginUser
     * @summary Log in the user with email and password
     * @request POST:/users/login
     * @response `200` `User`
     * @response `default` `Error`
     */
    loginUser: (data, params = {}) =>
      this.request({
        path: `/users/login`,
        method: "POST",
        body: data,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users
     * @name GetCurrentUser
     * @summary Get logged in user data
     * @request GET:/users/me
     * @secure
     * @response `200` `User`
     * @response `default` `Error`
     */
    getCurrentUser: (params = {}) =>
      this.request({
        path: `/users/me`,
        method: "GET",
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users
     * @name UpdateUser
     * @summary Change the user's data
     * @request PATCH:/users/me
     * @secure
     * @response `200` `User`
     * @response `default` `Error`
     */
    updateUser: (data, params = {}) =>
      this.request({
        path: `/users/me`,
        method: "PATCH",
        body: data,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Users
     * @name UpdatePassword
     * @summary Change the user's password
     * @request PATCH:/users/me/password
     * @secure
     * @response `200` `User`
     * @response `default` `Error`
     */
    updatePassword: (data, params = {}) =>
      this.request({
        path: `/users/me/password`,
        method: "PATCH",
        body: data,
        secure: true,
        ...params,
      }),
  };
  map = {
    /**
     * No description
     *
     * @tags Map
     * @name GetMapAreaRating
     * @summary Get rating for area with extents
     * @request GET:/map/areaRating
     * @secure
     * @response `200` `(AreaRating)[]`
     * @response `default` `Error`
     */
    getMapAreaRating: (query, params = {}) =>
      this.request({
        path: `/map/areaRating`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Map
     * @name GetMapCropMap
     * @summary Get crop map data for maps with extents
     * @request GET:/map/cropMap
     * @secure
     * @response `200` `(CropMap)[]`
     * @response `default` `Error`
     */
    getMapCropMap: (query, params = {}) =>
      this.request({
        path: `/map/cropMap`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Map
     * @name GetMapDroughtDates
     * @summary Get dates on which we have drought data
     * @request GET:/map/drought/dates
     * @secure
     * @response `200` `(string)[]`
     * @response `default` `Error`
     */
    getMapDroughtDates: (query, params = {}) =>
      this.request({
        path: `/map/drought/dates`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Map
     * @name GetMapDrought
     * @summary Get drought data for maps with extents
     * @request GET:/map/drought
     * @secure
     * @response `200` `(Drought)[]`
     * @response `default` `Error`
     */
    getMapDrought: (query, params = {}) =>
      this.request({
        path: `/map/drought`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Map
     * @name GetMapHarvest
     * @summary Get harvest dyanimcs data for maps with extents
     * @request GET:/map/harvest
     * @secure
     * @response `200` `(HarvestDynamics)[]`
     * @response `default` `Error`
     */
    getMapHarvest: (query, params = {}) =>
      this.request({
        path: `/map/harvest`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Map
     * @name GetMapZones
     * @summary Get zone layer data for maps with extents
     * @request GET:/map/zones
     * @secure
     * @response `200` `(ZoneLayer)[]`
     * @response `default` `Error`
     */
    getMapZones: (query, params = {}) =>
      this.request({
        path: `/map/zones`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Map
     * @name GetMapTiles
     * @summary Get tiled data for maps
     * @request GET:/map/tiles/{type}/{z}/{x}/{y}/image.png
     * @secure
     * @response `200` `Image`
     * @response `default` `Error`
     */
    getMapTiles: (type, x, y, z, params = {}) =>
      this.request({
        path: `/map/tiles/${type}/${z}/${x}/${y}/image.png`,
        method: "GET",
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Map
     * @name GetMapWms
     * @summary Get WMS data for maps
     * @request GET:/map/wms/{code}
     * @secure
     * @response `200` `Image`
     * @response `default` `Error`
     */
    getMapWms: ({ code, ...query }, params = {}) =>
      this.request({
        path: `/map/wms/${code}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Map
     * @name GetMapWmsDates
     * @summary Get WMS data for maps
     * @request GET:/map/wms/dates/{code}
     * @secure
     * @response `200` `(AlertingDate)[]`
     * @response `default` `Error`
     */
    getMapWmsDates: ({ code, ...query }, params = {}) =>
      this.request({
        path: `/map/wms/dates/${code}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  syncService = {
    /**
     * No description
     *
     * @tags SyncService
     * @name CreateFromSyncService
     * @summary Create incoming EK from SyncService
     * @request POST:/syncService/documents
     * @secure
     * @response `200` `ClientWithRelations`
     * @response `default` `Error`
     */
    createFromSyncService: (data, params = {}) =>
      this.request({
        path: `/syncService/documents`,
        method: "POST",
        body: data,
        secure: true,
        ...params,
      }),
  };
}
