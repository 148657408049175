import React from 'react';
import ClientBlock from './ClientBlock';
import DocumentBlock from './documentblock/DocumentBlock';
import FieldBlock from './FieldBlock';
import MeparBlock from './MeparBlock';
import NoDataBlock from './NoDataBlock';

const DataChildBlock = ({ type }) => {
    if (type === 'document') {
        return <DocumentBlock />;
    }

    if (type === 'mepar') {
        return <MeparBlock />;
    }

    if (type === 'field') {
        return <FieldBlock />;
    }

    if (type === 'client') {
        return <ClientBlock />;
    }

    return <NoDataBlock />;
};

export default DataChildBlock;
