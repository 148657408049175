import { CloseRounded, ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { forwardRef } from 'react';
import { OVERVIEW_STATES } from '../../constants';
import { useAppControl } from '../../providers/AppContext';
import { useLayout } from '../../providers/LayoutContext';
import DataBlock from './components/DataBlock';

const getOverviewHeight = (state) => {
    switch (state) {
        case OVERVIEW_STATES.OPEN_FULLSCREEN:
            return 'calc(100vh - 175px)';
        case OVERVIEW_STATES.OPEN:
            return 'calc((100vh - 144px) / 2.5)';
        case OVERVIEW_STATES.HIDDEN:
            return '0px';
        default:
            return '0px';
    }
};

const DataOverview = (_, ref) => {
    const { overviewState, setOverviewState } = useAppControl();
    const { setSelectedProduct } = useLayout();
    const map = useMap();

    const handleExpandLess = () => {
        setOverviewState((prevState) =>
            prevState === OVERVIEW_STATES.OPEN ? OVERVIEW_STATES.HIDDEN : OVERVIEW_STATES.OPEN
        );
    };

    const handleExpandMore = () => {
        setOverviewState((prevState) =>
            prevState === OVERVIEW_STATES.HIDDEN
                ? OVERVIEW_STATES.OPEN
                : OVERVIEW_STATES.OPEN_FULLSCREEN
        );
    };

    const handleClose = () => {
        setOverviewState(OVERVIEW_STATES.CLOSED);
        setSelectedProduct(null);
        map.setSelected(null);
    };

    return (
        overviewState !== OVERVIEW_STATES.CLOSED && (
            <Grid
                container
                ref={ref}
                spacing={0}
                sx={{
                    display: !overviewState ? 'none' : 'block',
                    position: 'absolute',
                    zIndex: 1051,
                    height: getOverviewHeight(overviewState),
                    bottom: 15,
                    paddingRight: '16px',
                }}
            >
                <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        backgroundColor: '#F0F4F7',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        height: '28px',
                        '& button:last-child': {
                            marginRight: '10px',
                        },
                    }}
                >
                    <IconButton
                        size="small"
                        disabled={overviewState === OVERVIEW_STATES.HIDDEN}
                        onClick={handleExpandLess}
                    >
                        <ExpandMoreRounded fontSize="10px" />
                    </IconButton>
                    <IconButton
                        size="small"
                        disabled={overviewState === OVERVIEW_STATES.OPEN_FULLSCREEN}
                        onClick={handleExpandMore}
                    >
                        <ExpandLessRounded fontSize="10px" />
                    </IconButton>
                    <IconButton size="small" onClick={handleClose}>
                        <CloseRounded fontSize="10px" />
                    </IconButton>
                </Grid>
                <DataBlock />
            </Grid>
        )
    );
};

export default forwardRef(DataOverview);
