import { Grid } from '@mui/material';
import { MAP_EVENTS, useMap } from 'es-map-widget';
import React, { createRef, useCallback, useEffect } from 'react';
import { OVERVIEW_STATES } from '../constants';
import { useAppControl } from '../providers/AppContext';
import { useLayout } from '../providers/LayoutContext';
import MapController from './mapcontroller/MapController';
import MapWrapper from './MapWrapper';
import DataOverview from './overview/DataOverview';

const ContentArea = () => {
    const { setSelectedProduct } = useLayout();
    const { setOverviewState } = useAppControl();
    const overview = createRef();
    const map = useMap();

    const onSelection = useCallback(
        (selectedElement) => {
            if (!selectedElement) {
                setOverviewState(OVERVIEW_STATES.CLOSED);
                setSelectedProduct(null);
                return;
            }

            setOverviewState(OVERVIEW_STATES.OPEN);
            setSelectedProduct(selectedElement);
        },
        [setOverviewState, setSelectedProduct]
    );

    useEffect(() => {
        const selectionListener = map.addEventListener(MAP_EVENTS.selection, () => {
            onSelection(map.getSelected());
        });
        return () => map.removeEventListener(MAP_EVENTS.selection, selectionListener);
    }, [map, onSelection]);

    return (
        <Grid container item xs={12} md={9} sx={{ position: 'relative' }}>
            <Grid item sx={{ width: '100%' }}>
                <MapController />
            </Grid>
            <Grid
                item
                sx={{ position: 'relative', height: 'calc(100% - 52.5px)', width: '100%' }}
                alignItems="stretch"
            >
                <MapWrapper overviewRef={overview} />
            </Grid>
            <DataOverview ref={overview} />
        </Grid>
    );
};

export default ContentArea;
