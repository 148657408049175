import { Fill, Style } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { categoryColors } from '../../constants';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import formatErrorMessage from '../../utils/formatErrorMessage';

export default function ZoneDataprovider({ extent, zoom, layerData, setSource }) {
    const { notifyError } = useNotify();

    const getZoneLayer = useCallback(
        async (controller, params) =>
            api.map.getMapZones(params, { signal: controller.signal }).then((res) => res.data),
        [api]
    );

    const addStyleToZones = (zonesWithoutStyle) =>
        zonesWithoutStyle.map((e) => ({
            ...e,
            style: new Style({
                fill: new Fill({
                    color: categoryColors[e.zone],
                }),
            }),
        }));

    useEffect(() => {
        if (layerData.isLayerShown === false || zoom < 8.5) {
            setSource(null);
            return;
        }

        const controller = new AbortController();

        getZoneLayer(controller, { extent })
            .then((newZoneLayer) =>
                setSource(
                    addStyleToZones(newZoneLayer.filter((e) => layerData.ids?.includes(e.fieldId)))
                )
            )
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(formatErrorMessage(err));
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getZoneLayer, extent, layerData, addStyleToZones, setSource]);

    return null;
}
