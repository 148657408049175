import { Fill, Style } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { ratingColors } from '../../constants';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import formatErrorMessage from '../../utils/formatErrorMessage';

export default function AreaRatingDataprovider({ extent, zoom, layerData, setSource }) {
    const { notifyError } = useNotify();
    const getAreaLayer = useCallback(
        async (controller, params) =>
            api.map.getMapAreaRating(params, { signal: controller.signal }).then((res) => res.data),
        [api]
    );

    const addStyleToAreas = (areaWithoutStyle) =>
        areaWithoutStyle.map((e) => ({
            ...e,
            style: new Style({
                fill: new Fill({
                    color: ratingColors[e.category],
                }),
            }),
        }));

    useEffect(() => {
        if (layerData.isLayerShown === false || zoom < 8.5) {
            setSource(null);
            return;
        }
        const controller = new AbortController();

        getAreaLayer(controller, { extent })
            .then((newAreaLayer) => {
                setSource(addStyleToAreas(newAreaLayer));
            })
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(formatErrorMessage(err));
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getAreaLayer, extent, layerData.showLayer]);
    return null;
}
