import { TextField } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { format, getDate, getMonth } from 'date-fns';
import { useMap } from 'es-map-widget';
import React, { useCallback, useEffect, useState } from 'react';
import { MAP_LAYER_NAMES } from '../../../constants/MapLayerNames';
import { useApi } from '../../../hooks/useApi';
import { useAppControl } from '../../../providers/AppContext';
import { useDroughtMenu } from '../contexts/DroughtContext';

const DroughtDataController = ({ isLayerVisible }) => {
    const map = useMap();
    const { year } = useAppControl();
    const { droughtData, setDroughtData, droughtMonth, setDroughtMonth } = useDroughtMenu();

    const [minDate, setMinDate] = useState(new Date(year, 0, 1));
    const [maxDate, setMaxDate] = useState(new Date(year, 11, 31));

    const [dates, error, loading] = useApi(
        (api, params) => api.map.getMapDroughtDates({ year, month: droughtMonth + 1 }, params),
        [droughtMonth, year]
    );

    const setDrought = useCallback(
        (newValue) => {
            setDroughtData(newValue);
            map.setLayerData(MAP_LAYER_NAMES.DROUGHT, newValue);
            map.setLayerVisibility(MAP_LAYER_NAMES.DROUGHT, isLayerVisible);
        },
        [map, setDroughtData, isLayerVisible]
    );

    const createDate = (date) => {
        return new Date(
            year,
            getMonth(date) || droughtMonth,
            date?.getDate() || droughtData.date.getDate()
        );
    };

    useEffect(() => {
        setMinDate(new Date(year, 0, 1));
        setMaxDate(new Date(year, 11, 31));
        setDroughtData((prevData) => ({
            ...prevData,
            droughtDate: new Date(
                year,
                getMonth(prevData.droughtDate),
                getDate(prevData.droughtDate)
            ),
        }));
    }, [year, setMinDate, setMaxDate]);

    return (
        <StaticDatePicker
            views={['day']}
            inputFormat="yyyy-MM-dd"
            displayStaticWrapperAs="desktop"
            disableFuture
            loading={loading && !error}
            minDate={minDate}
            maxDate={maxDate}
            value={droughtData.droughtDate}
            onChange={(date) =>
                setDrought({
                    isDroughtShown: isLayerVisible,
                    droughtDate: createDate(date),
                })
            }
            shouldDisableDate={(date) => {
                return !(dates || []).includes(format(createDate(date), 'yyyy-MM-dd'));
            }}
            onMonthChange={(m) => {
                const monthNumber = getMonth(m);
                setDroughtMonth(monthNumber);
            }}
            renderInput={(params) => <TextField {...params} />}
        />
    );
};

export default DroughtDataController;
