import { Grid } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useState } from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/MapLayerNames';
import DroughtDataController from '../components/DroughtDataController';
import { useMenu } from '../contexts/MenuContext';
import MapControllerButton from '../menu/MapControllerButton';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';
import SubMenuWrapper from '../menu/SubMenuWrapper';

const RiskFactorController = () => {
    const map = useMap();
    const { checkedItems, handleItemCheck } = useMenu();
    const [selectedOption, setSelectedOption] = useState('drought');
    const [isDroughtLayerVisible, setIsDroughtLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.RISK_FACTOR].includes(CONTROLLER_KEYS.DROUGHT)
    );
    const [isFrostLayerVisible, setIsFrostLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.RISK_FACTOR].includes(CONTROLLER_KEYS.FROST_MAP)
    );
    const [isWaterLoggingLayerVisible, setIsWaterLoggingLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.RISK_FACTOR].includes(CONTROLLER_KEYS.WATER_LOGGING)
    );

    return (
        <MapControllerButton
            label={CONTROLLER_LABELS.RISK_FACTOR}
            menuKey={CONTROLLER_KEYS.RISK_FACTOR}
        >
            <Grid container>
                <Grid item xs={6} className="dropdown-level-1">
                    <MapControllerMenuItem
                        isActive={selectedOption === 'drought'}
                        onClick={() => setSelectedOption('drought')}
                        text={CONTROLLER_LABELS.DROUGHT}
                        checkbox={{
                            checked: isDroughtLayerVisible,
                            onChange: () => {
                                setIsDroughtLayerVisible((pV) => !pV);
                                map.setLayerVisibility(
                                    MAP_LAYER_NAMES.DROUGHT,
                                    !isDroughtLayerVisible
                                );
                                handleItemCheck(
                                    CONTROLLER_KEYS.DROUGHT,
                                    CONTROLLER_KEYS.RISK_FACTOR
                                );
                            },
                        }}
                        hasSubMenu
                    />
                    <MapControllerMenuItem
                        text={CONTROLLER_LABELS.FROST}
                        checkbox={{
                            checked: isFrostLayerVisible,
                            onChange: () => {
                                setIsFrostLayerVisible((pV) => !pV);
                                map.setLayerVisibility(MAP_LAYER_NAMES.FROST, !isFrostLayerVisible);
                                handleItemCheck(CONTROLLER_KEYS.FROST, CONTROLLER_KEYS.RISK_FACTOR);
                            },
                        }}
                    />
                    <MapControllerMenuItem
                        text={CONTROLLER_LABELS.WATER_LOGGING}
                        checkbox={{
                            checked: isWaterLoggingLayerVisible,
                            onChange: () => {
                                setIsWaterLoggingLayerVisible((pV) => !pV);
                                map.setLayerVisibility(
                                    MAP_LAYER_NAMES.WATER_LOGGING,
                                    !isWaterLoggingLayerVisible
                                );
                                handleItemCheck(
                                    CONTROLLER_KEYS.WATER_LOGGING,
                                    CONTROLLER_KEYS.RISK_FACTOR
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={6} className="dropdown-level-2">
                    <SubMenuWrapper isVisible={selectedOption === 'drought'}>
                        <DroughtDataController isLayerVisible={isDroughtLayerVisible} />
                    </SubMenuWrapper>
                </Grid>
            </Grid>
        </MapControllerButton>
    );
};

export default RiskFactorController;
