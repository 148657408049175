import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMap } from 'es-map-widget';
import SideBar from '../../components/Sidebar';
import { MAP_LAYER_NAMES } from '../../constants/MapLayerNames';
import { useApi } from '../../hooks/useApi';
import { useAppControl } from '../../providers/AppContext';
import { useLayout } from '../../providers/LayoutContext';
import generateChartData from '../../utils/chartFunctions';
import { OVERVIEW_STATES } from '../../constants';

const DocumentPage = () => {
    const { id } = useParams();
    const map = useMap();
    const { setSelectedProduct } = useLayout();
    const { year, setOverviewState } = useAppControl();
    const [pieChartData, setPieChartData] = useState([]);
    const [nodes, setNodes] = useState([]);

    const [result, loading, error] = useApi(
        (api, params) => api.documents.getDocument({ code: id, year }, params),
        [id, year]
    );

    useEffect(() => {
        if (!result) {
            setOverviewState(OVERVIEW_STATES.CLOSED);
            setSelectedProduct(null);
            return;
        }

        map.setLayerData(MAP_LAYER_NAMES.MEPAR, {
            codes: result.mepars.map((mepar) => mepar.code),
        });
        map.setLayerData(MAP_LAYER_NAMES.FIELDS, {
            ids: result.mepars.flatMap((mepar) => mepar.fields.map((f) => f.geoApiId)),
        });
        map.setLayerData(MAP_LAYER_NAMES.ZONE, {
            ids: result.mepars.flatMap((mepar) => mepar.fields.map((f) => f.geoApiId)),
        });

        setNodes(
            result.mepars.map((mepar) => ({
                code: mepar.code,
                type: mepar.type,
                name: `${mepar.code} (${mepar.fields.length} tábla)`,
                children: mepar.fields.map((f) => ({
                    code: f.geoApiId,
                    fieldNumber: f.fieldNumber,
                    type: 'field',
                    name: `${f.mainPlantName} (${f.areaDrawn} ha) (Tábla sorszáma: ${f.fieldNumber})`,
                    document: {
                        code: result.code,
                        client: {
                            code: result.applierId,
                        },
                    },
                })),
            }))
        );

        setPieChartData(
            generateChartData(result.crops, (dataToPieChart) =>
                dataToPieChart.map((item) => {
                    return {
                        id: item.crop,
                        label: item.crop,
                        value: item.area.toFixed(2),
                    };
                })
            )
        );

        const documentGeoms = result.mepars.filter((e) => e.geom).map((e) => ({ geom: e.geom }));
        if (documentGeoms.length > 0) {
            map.zoomToGeomsExtent(documentGeoms);
        }
    }, [result, setNodes, setPieChartData, generateChartData]);

    return (
        <SideBar
            result={result}
            nodes={nodes}
            pieChartData={pieChartData}
            error={error}
            loading={loading}
            dataType="document"
        />
    );
};

export default DocumentPage;
