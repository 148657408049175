/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { AddRounded, ChevronRightRounded, RemoveRounded } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { useMap } from 'es-map-widget';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { OVERVIEW_STATES } from '../constants';
import { MAP_LAYER_NAMES } from '../constants/MapLayerNames';
import { useAppControl } from '../providers/AppContext';
import { useLayout } from '../providers/LayoutContext';
import LinkButton from './atom/LinkButton';

export const generateLinkToProduct = (type, text) => {
    switch (type) {
        case 'document':
            return `/products/mepar/${text}`;
        case 'mepar':
            return `/products/client/${text}`;
        case 'client':
            return `/products/mepar/${text}`;
        default:
            return '/';
    }
};

const LinkAccordion = ({ nodes, dataType }) => {
    const map = useMap();
    const { selectedProduct, setSelectedProduct } = useLayout();
    const { overviewState, setOverviewState } = useAppControl();
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        setExpanded(nodes.map((e) => e.name));
    }, [nodes, setExpanded]);

    const toggleExpanded = (meparCode) => {
        setExpanded((prevValues) => {
            if (prevValues.includes(meparCode)) {
                return prevValues.filter((e) => e !== meparCode);
            }
            return [...prevValues, meparCode];
        });
    };

    const expandAll = () => setExpanded(nodes.map((e) => e.name));

    const collapseAll = () => setExpanded([]);

    const handleClick = (child) => {
        setOverviewState(OVERVIEW_STATES.OPEN);
        setSelectedProduct(child);
        map.setSelected(child, MAP_LAYER_NAMES.FIELDS);
    };

    if (nodes.length === 0) {
        return null;
    }

    return (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1" fontWeight={700} sx={{ mt: 3, mb: 1 }}>
                {
                    // eslint-disable-next-line no-nested-ternary
                    dataType === 'document'
                        ? 'EK-hoz tartozó MePAR blokkok'
                        : dataType === 'mepar'
                        ? 'MePAR blokkhoz kapcsolódó ügyfelek'
                        : 'Ügyfélhez kapcsolódó MePAR blokkok'
                }
            </Typography>

            <div style={{ marginBottom: '8px' }}>
                {expanded.length > 0 ? (
                    <LinkButton startIcon={<RemoveRounded />} onClick={() => collapseAll()}>
                        Összes bezárása ({nodes.length})
                    </LinkButton>
                ) : (
                    <LinkButton startIcon={<AddRounded />} onClick={() => expandAll()}>
                        Összes kinyitása ({nodes.length})
                    </LinkButton>
                )}
            </div>
            <div style={{ overflow: 'auto' }}>
                {nodes.map((node) => (
                    <div style={{ marginBottom: '8px' }} key={node.code}>
                        <Accordion
                            expanded={expanded.includes(node.name)}
                            sx={{ backgroundColor: '#fff' }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    expanded.includes(node.name) ? (
                                        <RemoveRounded onClick={() => toggleExpanded(node.name)} />
                                    ) : (
                                        <AddRounded onClick={() => toggleExpanded(node.name)} />
                                    )
                                }
                                sx={{ borderBottom: '1px solid #d9e0e5' }}
                            >
                                <Link
                                    to={generateLinkToProduct(dataType, node.code)}
                                    onClick={() => {
                                        setOverviewState(OVERVIEW_STATES.CLOSED);
                                        setSelectedProduct(null);
                                    }}
                                >
                                    {node.name}
                                </Link>
                            </AccordionSummary>
                            <AccordionDetails>
                                {node.children.map((child) => (
                                    <div
                                        className={clsx(
                                            'connecting-item',
                                            selectedProduct?.code === child.code &&
                                                overviewState === OVERVIEW_STATES.OPEN
                                                ? 'active'
                                                : ''
                                        )}
                                        key={child.code}
                                        onClick={() => handleClick(child)}
                                    >
                                        {child.name}
                                        <ChevronRightRounded />
                                    </div>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}
            </div>
        </Grid>
    );
};

export default LinkAccordion;
