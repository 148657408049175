export default function formatErrorMessage(error) {
    if (process.env.NODE_ENV === 'dev') {
        console.error(error?.response?.data?.message);
    }

    if (error.response?.status === 500) {
        return 'Internal server error';
    }

    return error.response?.data?.message || 'Hiba a művelet során';
}
