import { TableCell } from '@mui/material';
import React from 'react';

const renderLandscapeValueSwitch = (data) => {
    Object.entries(data.landscapeValue).map(([key, value]) => {
        switch (key) {
            case 'kurgan':
                return (
                    <>
                        <TableCell>Kunhalom</TableCell>
                        <TableCell>{value || '0'} ha</TableCell>
                    </>
                );
            case 'tree':
                return (
                    <>
                        <TableCell>Magányosan álló fa</TableCell>
                        <TableCell>{value || '0'} db</TableCell>
                    </>
                );
            case 'terrace':
                return (
                    <>
                        <TableCell>Terasz</TableCell>
                        <TableCell>{value || '0'} ha</TableCell>
                    </>
                );
            case 'craneWell':
                return (
                    <>
                        <TableCell>Gémeskút</TableCell>
                        <TableCell>{value || '0'} db</TableCell>
                    </>
                );
            case 'bushes':
                return (
                    <>
                        <TableCell>Fa- és bokorcsoport</TableCell>
                        <TableCell>{value || '0'} ha</TableCell>
                    </>
                );
            case 'pond':
                return (
                    <>
                        <TableCell>Kis kiterjedésű tó</TableCell>
                        <TableCell>{value || '0'} ha</TableCell>
                    </>
                );
            default:
                return null;
        }
    });
};
export default renderLandscapeValueSwitch;
