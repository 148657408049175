import { FormControl, FormGroup, Grid, RadioGroup, TextField } from '@mui/material';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { format, getDate, getMonth } from 'date-fns';
import { useMap } from 'es-map-widget';
import { sortBy } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LOGGED_IN_USER_KEY } from '../../../config';
import { BASE_COLORS, CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/MapLayerNames';
import { useApi } from '../../../hooks/useApi';
import { useAppControl } from '../../../providers/AppContext';
import { useAlertMenu } from '../contexts/AlertContext';
import { useMenu } from '../contexts/MenuContext';
import SubMenuCheckbox from '../menu/SubMenuCheckbox';
import SubMenuRadio from '../menu/SubMenuRadio';

const AlertDataController = ({ isLayerVisible }) => {
    const map = useMap();
    const { id } = useParams();
    const { pathname } = useLocation();
    const { year } = useAppControl();
    const { alertData, setAlertData, alertMonth, setAlertMonth } = useAlertMenu();
    const { menuOpen } = useMenu();
    const user = JSON.parse(localStorage.getItem(LOGGED_IN_USER_KEY));

    const [minDate, setMinDate] = useState(new Date(year, 0, 1));
    const [maxDate, setMaxDate] = useState(new Date(year, 11, 31));
    const [disabledDates, setDisabledDates] = useState([]);
    const [alertDates, setAlertDates] = useState([]);

    const setAlertArea = useCallback(
        (newValue) => {
            const date = new Date(
                alertDates.find((e) => e.date === format(newValue.date, 'yyyy-MM-dd'))?.nextDate ||
                    newValue.date
            );
            setAlertData((p) => ({ ...p, area: newValue.area }));
            map.setLayerVisibility(MAP_LAYER_NAMES.ALERT_AREA, newValue.area);
            map.setLayerData(MAP_LAYER_NAMES.ALERT_AREA, { area: newValue.area, date });
        },
        [map, alertDates, setAlertData]
    );

    const setAlert = useCallback(
        (newValue) => {
            setAlertData(newValue);
            map.setLayerData(MAP_LAYER_NAMES.ALERT_WMS, { ...newValue, token: user.token });
        },
        [map, setAlertData]
    );

    const [dates, loading, error] = useApi(
        (api, params) =>
            api.map.getMapWmsDates(
                { code: pathname.includes('mepar') ? id : undefined, year, month: alertMonth + 1 },
                { ...params }
            ),
        [year, alertMonth, menuOpen]
    );
    const [nextMonthDates] = useApi(
        (api, params) =>
            api.map.getMapWmsDates(
                { code: pathname.includes('mepar') ? id : undefined, year, month: alertMonth + 2 },
                { ...params }
            ),
        [year, alertMonth, menuOpen]
    );

    useEffect(() => {
        if (!dates || !nextMonthDates) {
            return;
        }

        const allDates = [...dates, ...nextMonthDates];
        const ndviDates = dates.map((d) => d.date);
        const alerts = allDates.filter((e) => e.alert).map((e) => e.date);
        const alertsWithNextDate = sortBy((e) => e.date, allDates).map((e, i, arr) => ({
            date: e.date,
            nextDate: alerts.includes(arr[i + 1]?.date) ? arr[i + 1].date : null,
            alert: e.alert,
        }));
        setDisabledDates(ndviDates);
        setAlertDates(alertsWithNextDate);
    }, [dates, nextMonthDates]);

    const createDate = useCallback(
        (date) => {
            return new Date(
                year,
                getMonth(date) || alertMonth,
                date?.getDate() || alertData.date.getDate()
            );
        },
        [year, alertMonth, alertData.date]
    );

    useEffect(() => {
        setMinDate(new Date(year, 0, 1));
        setMaxDate(new Date(year, 11, 31));
        setAlertData((prevData) => ({
            ...prevData,
            date: new Date(year, getMonth(prevData.date), getDate(prevData.date)),
        }));
    }, [year, setMinDate, setMaxDate]);

    useEffect(() => {
        if (!isLayerVisible) {
            map.setLayerVisibility(MAP_LAYER_NAMES.ALERT_AREA, false);
        } else {
            map.setLayerVisibility(MAP_LAYER_NAMES.ALERT_AREA, alertData.area);
        }
    }, [alertData.area, map, isLayerVisible]);

    return (
        <Grid container justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
                <FormGroup>
                    <SubMenuCheckbox
                        checked={alertData.area}
                        onChange={() =>
                            setAlertArea({
                                area: !alertData.area,
                                date: alertData.date,
                            })
                        }
                        disabled={!isLayerVisible}
                        label={CONTROLLER_LABELS.ALERT_AREA}
                    />
                    <FormControl>
                        <RadioGroup
                            defaultValue={CONTROLLER_KEYS.ALERT_NDVI}
                            name="map-controller-radio-group"
                            value={
                                !alertData.isVisible
                                    ? CONTROLLER_KEYS.ALERT_NDVI
                                    : CONTROLLER_KEYS.ALERT_VISIBLE
                            }
                            onChange={() => {
                                setAlert({
                                    isVisible: !alertData.isVisible,
                                    date: alertData.date,
                                    type: alertData.isVisible ? 'BMI' : 'TCI',
                                    area: alertData.area,
                                });
                            }}
                        >
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.ALERT_NDVI}
                                label={CONTROLLER_LABELS.ALERT_NDVI}
                                disabled={!isLayerVisible}
                            />
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.ALERT_VISIBLE}
                                label={CONTROLLER_LABELS.ALERT_VISIBLE}
                                disabled={!isLayerVisible}
                            />
                        </RadioGroup>
                    </FormControl>
                </FormGroup>
            </Grid>
            <Grid>
                <StaticDatePicker
                    views={['day']}
                    inputFormat="yyyy-MM-dd"
                    displayStaticWrapperAs="desktop"
                    disableFuture
                    minDate={minDate}
                    maxDate={maxDate}
                    value={alertData.date}
                    loading={loading && !error}
                    onChange={(date) => {
                        setAlert({
                            isVisible: alertData.isVisible,
                            date,
                            type: alertData.type,
                            area: alertData.area,
                        });
                        setAlertArea({
                            date,
                            area: alertData.area,
                        });
                    }}
                    shouldDisableDate={(date) =>
                        !disabledDates.includes(format(createDate(date), 'yyyy-MM-dd'))
                    }
                    onMonthChange={(m) => setAlertMonth(getMonth(m))}
                    renderInput={(params) => <TextField {...params} />}
                    renderDay={(day, selectedDays, pickersDayProps) => {
                        const currentDay = format(day, 'yyyy-MM-dd');
                        const alerts = alertDates.filter((e) => e.alert).map((e) => e.date);

                        return (
                            <PickersDay
                                {...pickersDayProps}
                                sx={{
                                    borderRadius: 10,
                                    border: alerts.includes(currentDay) ? '1px solid red' : '',
                                }}
                            >
                                {day.getDate()}
                            </PickersDay>
                        );
                    }}
                    sx={{
                        backgroundColor: BASE_COLORS.PRIMARY_MAIN,
                    }}
                    disabled={!isLayerVisible}
                />
            </Grid>
        </Grid>
    );
};

export default AlertDataController;
