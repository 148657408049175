import { ChevronLeftRounded } from '@mui/icons-material';
import { Alert, Grid } from '@mui/material';
import React from 'react';
import { OVERVIEW_STATES } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';
import { useLayout } from '../../../providers/LayoutContext';
import LinkButton from '../../atom/LinkButton';

const NoDataBlock = () => {
    const { setSelectedProduct } = useLayout();
    const { setOverviewState } = useAppControl();

    return (
        <Grid
            container
            sx={{
                p: 3,
                height: '100%',
                backgroundColor: '#fff',
                overflow: 'auto',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
            }}
            alignContent="flex-start"
        >
            <Grid item xs={12} sx={{ pb: 2 }}>
                <LinkButton
                    onClick={() => {
                        setOverviewState(OVERVIEW_STATES.CLOSED);
                        setSelectedProduct(null);
                    }}
                    startIcon={<ChevronLeftRounded />}
                >
                    Bezárás
                </LinkButton>
            </Grid>
            <Grid item xs={12}>
                <Alert severity="error">Valami hiba történt!</Alert>
            </Grid>
        </Grid>
    );
};

export default NoDataBlock;
