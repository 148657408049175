import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';
import renderLandscapeValueSwitch from '../../../../../utils/documentFunctions';

const LandscapeValue = ({ data }) => {
    return (
        <Accordion elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography variant="h6">Védett tájképi elemek összesítése</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Megnevezés</TableCell>
                                <TableCell>Védett tájképi elemek összesítése</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.landscapeValue && renderLandscapeValueSwitch(data)}
                        </TableBody>
                    </Table>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default LandscapeValue;
