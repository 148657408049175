import { createTheme } from '@mui/material';
import { BASE_COLORS } from './constants';

const theme = createTheme({
    typography: {
        fontFamily: 'Open Sans',
        h1: {
            fontSize: 48,
            fontWeight: 700,
        },
        h2: {
            fontSize: 40,
            fontWeight: 700,
        },
        h3: {
            fontSize: 32,
            fontWeight: 700,
        },
        h4: {
            fontSize: 24,
            fontWeight: 700,
        },
        h5: {
            fontSize: 18,
            fontWeight: 700,
        },
        h6: {
            fontSize: 16,
            fontWeight: 700,
        },
        body1: {
            fontSize: 18,
            fontWeight: 400,
        },
        body2: {
            fontSize: 14,
            fontWeight: 400,
        },
    },
    palette: {
        primary: {
            main: BASE_COLORS.PRIMARY_MAIN,
        },
        secondary: {
            main: BASE_COLORS.SECONDARY_MAIN,
        },
        background: {
            default: BASE_COLORS.BACKGROUND_DEFAULT,
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: 0,
                    margin: '8px 0',
                    '& .connecting-item': {
                        cursor: 'pointer',
                        fontSize: 14,
                        color: BASE_COLORS.TEXT_BLACK_COLOR,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '8px 16px',
                        '& svg': {
                            color: BASE_COLORS.TEXT_BLUE,
                        },
                        '&:hover, &.active': {
                            backgroundColor: BASE_COLORS.BACKGROUND_LIGHTER_GRAY,
                        },
                    },
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiAccordionSummary-root': {
                        minHeight: 'unset',
                        borderBottom: 0,
                        '& svg': {
                            color: BASE_COLORS.TEXT_BLUE,
                            '&:hover': {
                                borderRadius: 4,
                                backgroundColor: BASE_COLORS.BACKGROUND_WHITE,
                            },
                        },
                    },
                    '&.MuiAccordionSummary-root.Mui-expanded': {
                        minHeight: 'unset',
                        borderBottom: `1px solid ${BASE_COLORS.BACKGROUND_LIGHT_GRAY}`,
                    },
                    '& .MuiAccordionSummary-content.Mui-expanded': {
                        margin: '12px 0',
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    '&.controller-icon': {
                        color: BASE_COLORS.TEXT_BLUE,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.link-button': {
                        textTransform: 'none',
                        color: BASE_COLORS.TEXT_BLUE,
                        padding: 0,
                        '&:hover': {
                            backgroundColor: 'unset',
                        },
                    },
                    '&.map-controller-button': {
                        textTransform: 'none',
                        color: BASE_COLORS.WHITE,
                        boxShadow: 'unset',
                        '&>span:after': {
                            display: 'inline-block',
                            content: '""',
                            width: '8px',
                            height: '8px',
                            backgroundColor: 'inherit',
                            borderRadius: '50%',
                            marginLeft: '5px',
                            marginBottom: '1px',
                        },
                        '&>span.has-items-checked:after': {
                            backgroundColor: BASE_COLORS.WHITE,
                        },
                    },
                },
            },
        },
        MuiAppBar: {
            defaultProps: {
                color: 'inherit',
            },
            styleOverrides: {
                root: {
                    backgroundColor: BASE_COLORS.PRIMARY_MAIN,
                    color: BASE_COLORS.WHITE,
                    '& .MuiInputLabel-root': {
                        color: `white !important`,
                    },
                    '& .MuiInputBase-root': {
                        borderColor: 'white',
                        color: 'inherit',
                    },
                    '& .MuiSelect-icon': {
                        fill: 'black',
                    },
                    '& .Mui-focused': {
                        color: 'white',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: `white !important`,
                    },
                    '& .MuiSvgIcon-root': {
                        color: `black`,
                    },
                    '& .MuiTypography-root': {
                        color: `white !important`,
                    },
                    '& .MuiAutocomplete-root': {
                        '& .Mui-focused': {
                            borderBottomLeftRadius: '0',
                            borderBottomRightRadius: '0',
                            borderBottom: '1px solid grey',
                        },
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.MuiMenu-paper': {
                        backgroundColor: BASE_COLORS.WHITE,
                    },
                    '&.basemap-menu': {
                        position: 'absolute',
                        right: 32,
                        backgroundColor: BASE_COLORS.WHITE,
                        zIndex: 100,
                        borderRadius: '10px',
                        padding: '10px 5px',
                    },
                    '&.basemap-controller': {
                        padding: '10px',
                        marginRight: '8px',
                    },
                    '&.map-controller': {
                        fontSize: '14px',
                    },
                    '&.map-controller-dropdown': {
                        position: 'relative',
                        zIndex: 1100,
                        padding: '20px',
                        backgroundColor: BASE_COLORS.PRIMARY_MAIN,
                        color: BASE_COLORS.WHITE,
                        marginTop: '20px',
                        '& .dropdown-level-1': {
                            width: '230px',
                            borderRight: `1px solid ${BASE_COLORS.WHITE}`,
                            paddingRight: '20px',
                            '&.no-border': {
                                border: 0,
                            },
                        },
                        '& .dropdown-level-2': {
                            width: '230px',
                            paddingLeft: '20px',
                        },
                    },
                    '&.map-controller-dropdown:after': {
                        content: '""',
                        position: 'absolute',
                        top: '5px',
                        left: '32px',
                        marginLeft: '-15px',
                        marginTop: '-15px',
                        // position: 'relative',
                        zIndex: 1,
                        height: 0,
                        borderBottom: `solid 15px ${BASE_COLORS.PRIMARY_MAIN}`,
                        borderLeft: 'solid 15px transparent',
                        borderRight: 'solid 15px transparent',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.map-controller-checkbox': {
                        color: BASE_COLORS.WHITE,
                        padding: '0 8px 0 0',
                        '&.Mui-checked': {
                            color: BASE_COLORS.WHITE,
                        },
                        '&.Mui-disabled': {
                            color: BASE_COLORS.GRAY,
                        },
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.map-controller-radio': {
                        color: BASE_COLORS.WHITE,
                        padding: '0 8px 0 0',
                        '&.Mui-checked': {
                            color: BASE_COLORS.WHITE,
                        },
                        '&.Mui-disabled': {
                            color: BASE_COLORS.GRAY,
                        },
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    backgroundColor: BASE_COLORS.WHITE,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '&.year-selector': {
                        backgroundColor: 'white',
                        '&.Mui-focused': {
                            color: BASE_COLORS.PRIMARY_MAIN,
                        },
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&.map-controller-arrow': {
                        color: BASE_COLORS.WHITE,
                        '&.Mui-disabled .MuiSvgIcon-root': {
                            color: BASE_COLORS.GRAY,
                        },
                    },
                    '&.base-map-icon': {
                        borderRadius: '8px',
                        backgroundColor: 'inherit',
                        color: BASE_COLORS.GRAY,
                        '&.selected': {
                            backgroundColor: BASE_COLORS.PRIMARY_MAIN,
                            color: BASE_COLORS.WHITE,
                        },
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '&.map-controller-checkbox-label': {
                        fontSize: '14px',
                        color: BASE_COLORS.WHITE,
                        '&.Mui-disabled': {
                            color: BASE_COLORS.GRAY,
                        },
                        '&:not(:last-child)': {
                            marginBottom: '8px',
                        },
                    },
                    '&.map-controller-radio-label': {
                        fontSize: '14px',
                        color: BASE_COLORS.WHITE,
                        '&.Mui-disabled': {
                            color: BASE_COLORS.GRAY,
                        },
                        '&:not(:last-child)': {
                            marginBottom: '8px',
                        },
                    },
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '&.map-controller-submenu-item': {
                        fontSize: '14px',
                        '&:not(:last-child)': {
                            marginBottom: '8px',
                        },
                    },
                },
            },
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                root: {
                    '& .MuiIconButton-root': {
                        color: BASE_COLORS.WHITE,
                        '&.Mui-disabled': {
                            color: BASE_COLORS.GRAY,
                        },
                    },
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: 0,
                },
                labelContainer: {
                    margin: '0 auto 0 0',
                },
                label: {
                    fontSize: '14px',
                },
            },
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    backgroundColor: BASE_COLORS.PRIMARY_MAIN,
                    margin: 0,
                    width: '220px',
                },
            },
        },
        MuiDayPicker: {
            styleOverrides: {
                weekDayLabel: {
                    color: 'white',
                    width: '24px',
                    height: '24px',
                },
                header: {
                    justifyContent: 'space-between',
                },
                weekContainer: {
                    justifyContent: 'space-between',
                    margin: 0,
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    height: '24px',
                    width: '24px',
                    backgroundColor: BASE_COLORS.PRIMARY_MAIN,
                    color: BASE_COLORS.WHITE,
                    '&.Mui-disabled': {
                        color: BASE_COLORS.GRAY,
                    },
                    '&.Mui-selected': {
                        color: BASE_COLORS.PRIMARY_MAIN,
                        backgroundColor: `${BASE_COLORS.WHITE} !important`,
                    },
                },
                today: {
                    backgroundColor: BASE_COLORS.WHITE,
                    color: BASE_COLORS.GRAY,
                },
            },
        },
        PrivatePickersSlideTransition: {
            styleOverrides: {
                root: {
                    minHeight: '150px !important',
                },
            },
        },
        MuiPickerStaticWrapper: {
            styleOverrides: {
                root: {
                    width: '220px !important',
                },
                content: {
                    minWidth: '220px !important',
                },
            },
        },
    },
});

export default theme;
