import { Button, CircularProgress, Grid, Link, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../hooks/useApi';
import { useAuth } from '../../../providers/AuthProvider';
import useNotify from '../../../hooks/useNotify';

function validateLoginForm(values) {
    const errors = {};

    if (!values.email) {
        errors.email = 'Az e-mail cím megadása kötelező';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Érvénytelen e-mail cím';
    }

    if (!values.password) {
        errors.password = 'A jelszó megadása kötelező';
    }

    return errors;
}

const LoginForm = () => {
    const { setUser } = useAuth();
    const { notifyError } = useNotify();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const initialValues = {
        email: '',
        password: '',
    };

    const submitLoginForm = ({ email, password }) => {
        setLoading(true);
        api.users
            .loginUser({ email, password })
            .then((res) => {
                const user = res.data;
                setUser({ name: user.name, email: user.email, token: user.token });
                api.setSecurityData(user.token);
                setLoading(false);
                navigate('/');
            })
            .catch(() => {
                notifyError('Hibás e-mail cím vagy jelszó');
                setLoading(false);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submitLoginForm}
            validate={validateLoginForm}
        >
            {({ values, handleChange, errors, touched }) => (
                <Form style={{ padding: '16px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="email"
                                label="E-mail cím"
                                value={values.email}
                                onChange={handleChange}
                                error={touched.email && errors.email}
                                helperText={touched.email && errors.email ? errors.email : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="password"
                                type="password"
                                label="Jelszó"
                                value={values.password}
                                onChange={handleChange}
                                error={touched.password && errors.password}
                                helperText={
                                    touched.password && errors.password ? errors.password : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button type="submit" variant="contained" fullWidth>
                                {!loading ? (
                                    'Belépés'
                                ) : (
                                    <CircularProgress color="secondary" size={25} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
