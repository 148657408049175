const CONTROLLER_KEYS = Object.freeze({
    DOCUMENT_DATA: 'DOCUMENT_DATA',
    MEPAR: 'MEPAR',
    MEPAR_ALL: 'MEPAR_ALL',
    MEPAR_CONNECTING: 'MEPAR_CONNECTING',
    TABLE: 'TABLE',
    TABLE_ALL: 'TABLE_ALL',
    TABLE_CONNECTING: 'TABLE_CONNECTING',
    TABLE_ACTIVE: 'TABLE_ACTIVE',
    MONITORING: 'MONITORING',
    CROP: 'CROP',
    COLE_SEED: 'COLE_SEED',
    CORN: 'CORN',
    SPIKE: 'SPIKE',
    SUNFLOWER: 'SUNFLOWER',
    RAPE_SEED: 'RAPE_SEED',
    WHEAT: 'WHEAT',
    ZONE: 'ZONE',
    HARVEST_MONITORING: 'HARVEST_MONITORING',
    RISK_FACTOR: 'RISK_FACTOR',
    DROUGHT: 'DROUGHT',
    FROST: 'FROST',
    FROST_MAP: 'FROST_MAP',
    FROST_INDEX: 'FROST_INDEX',
    WATER_LOGGING: 'WATER_LOGGING',
    YIELD: 'YIELD',
    YIELD_PREDICTIVE: 'YIELD_PREDICTIVE',
    YIELD_MAP: 'YIELD_MAP',
    SOIL_AND_TERRAIN: 'SOIL_AND_TERRAIN',
    MICRO_TERRAIN: 'MICRO_TERRAIN',
    SOIL_MAP: 'SOIL_MAP',
    EROSION: 'EROSION',
    ORTO_PHOTO: 'ORTO_PHOTO',
    ALERT: 'ALERT',
    ALERT_NDVI: 'ALERT_NDVI',
    ALERT_VISIBLE: 'ALERT_VISIBLE',
    ALERT_AREA: 'ALERT_AREA',
    AREA_RATING: 'AREA_RATING',
});

const CONTROLLER_LABELS = {
    [CONTROLLER_KEYS.DOCUMENT_DATA]: 'Ek Adatok',
    [CONTROLLER_KEYS.MEPAR]: 'MePAR blokk',
    [CONTROLLER_KEYS.MEPAR_ALL]: 'Összes',
    [CONTROLLER_KEYS.MEPAR_CONNECTING]: 'Kapcsolódó',
    [CONTROLLER_KEYS.TABLE]: 'Táblák',
    [CONTROLLER_KEYS.TABLE_ALL]: 'Összes',
    [CONTROLLER_KEYS.TABLE_CONNECTING]: 'Kapcsolódó',
    [CONTROLLER_KEYS.TABLE_ACTIVE]: 'Aktív',
    [CONTROLLER_KEYS.MONITORING]: 'Monitoring',
    [CONTROLLER_KEYS.CROP]: 'Vetéstérkép',
    [CONTROLLER_KEYS.SPIKE]: 'Kalászosok',
    [CONTROLLER_KEYS.COLE_SEED]: 'Őszi káposztarepce',
    [CONTROLLER_KEYS.CORN]: 'Kukorica',
    [CONTROLLER_KEYS.SUNFLOWER]: 'Napraforgó',
    [CONTROLLER_KEYS.RAPE_SEED]: 'Repce',
    [CONTROLLER_KEYS.WHEAT]: 'Búza',
    [CONTROLLER_KEYS.ZONE]: 'Zónatérkép',
    [CONTROLLER_KEYS.HARVEST_MONITORING]: 'Betakarítás monitoring',
    [CONTROLLER_KEYS.RISK_FACTOR]: 'Kockázati faktorok',
    [CONTROLLER_KEYS.FROST]: 'Fagyveszély',
    [CONTROLLER_KEYS.FROST_MAP]: 'Fagyveszély térkép',
    [CONTROLLER_KEYS.FROST_INDEX]: 'Fagyindex megjelenítése',
    [CONTROLLER_KEYS.DROUGHT]: 'Aszály monitoring',
    [CONTROLLER_KEYS.WATER_LOGGING]: 'Belvízveszélyeztetettség',
    [CONTROLLER_KEYS.YIELD]: 'Hozamtérképek',
    [CONTROLLER_KEYS.YIELD_PREDICTIVE]: 'Predikciós hozamtérkép',
    [CONTROLLER_KEYS.YIELD_MAP]: 'Hozamtérkép',
    [CONTROLLER_KEYS.SOIL_AND_TERRAIN]: 'Talaj és domborzati térképek',
    [CONTROLLER_KEYS.MICRO_TERRAIN]: 'Digitális mikrodomborzat modell',
    [CONTROLLER_KEYS.SOIL_MAP]: 'Talajtérkép',
    [CONTROLLER_KEYS.EROSION]: 'Erózió térkép',
    [CONTROLLER_KEYS.ORTO_PHOTO]: 'Ortofotó',
    [CONTROLLER_KEYS.ALERT]: 'Riasztás',
    [CONTROLLER_KEYS.ALERT_NDVI]: 'NDVI kép',
    [CONTROLLER_KEYS.ALERT_VISIBLE]: 'Látható kép',
    [CONTROLLER_KEYS.ALERT_AREA]: 'Riasztási terület',
    [CONTROLLER_KEYS.AREA_RATING]: 'Területminősítés',
};

export { CONTROLLER_KEYS, CONTROLLER_LABELS };
