import { ClickAwayListener, Grid, Paper, Popper } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useRef, useState } from 'react';
import { BASE_MAP } from '../../../config';
import { BASE_COLORS, MAP_KEYS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/MapLayerNames';
import BaseMapIcon from '../menu/BaseMapIcon';

function getMapState() {
    const mapState = localStorage.getItem(BASE_MAP);
    if (!mapState || mapState === 'undefined') {
        localStorage.setItem(BASE_MAP, MAP_KEYS.OSM);
        return MAP_KEYS.OSM;
    }
    return mapState;
}

const BaseMapController = ({ bottom }) => {
    const map = useMap();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMap, setSelectedMap] = useState(() => getMapState());
    const [isOrtoPhotoVisible, setIsOrtoPhotoVisible] = useState(false);
    const paperRef = useRef(null);

    const handleClick = () => {
        setAnchorEl(paperRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Paper className="basemap-menu" sx={{ bottom }} ref={paperRef}>
            <Grid container>
                <BaseMapIcon xs={12} text={selectedMap} onClick={handleClick} />
            </Grid>
            <Popper open={open} anchorEl={anchorEl} placement="left-end">
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper className="basemap-controller">
                        <Grid container>
                            <BaseMapIcon
                                text={MAP_KEYS.OSM}
                                isSelected={selectedMap === MAP_KEYS.OSM}
                                onClick={() => {
                                    setSelectedMap(MAP_KEYS.OSM);
                                    localStorage.setItem('basemap', MAP_KEYS.OSM);
                                    map.setLayerVisibility(MAP_LAYER_NAMES.OSM, true);
                                    map.setLayerVisibility(MAP_LAYER_NAMES.BING, false);
                                }}
                            />
                            <BaseMapIcon
                                text={MAP_KEYS.BING}
                                isSelected={selectedMap === MAP_KEYS.BING}
                                onClick={() => {
                                    setSelectedMap(MAP_KEYS.BING);
                                    localStorage.setItem('basemap', MAP_KEYS.BING);
                                    map.setLayerVisibility(MAP_LAYER_NAMES.OSM, false);
                                    map.setLayerVisibility(MAP_LAYER_NAMES.BING, true);
                                }}
                            />
                            <BaseMapIcon
                                text={MAP_KEYS.ORTHO}
                                isSelected={isOrtoPhotoVisible}
                                onClick={() => {
                                    setIsOrtoPhotoVisible((pV) => !pV);
                                    map.setLayerVisibility(
                                        MAP_LAYER_NAMES.ORTHO_PHOTO,
                                        !isOrtoPhotoVisible
                                    );
                                }}
                                otherStyles={{
                                    borderLeft: `1px solid ${BASE_COLORS.PRIMARY_MAIN}`,
                                }}
                            />
                        </Grid>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </Paper>
    );
};

export default BaseMapController;
