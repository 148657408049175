import { Fill, Style, Text } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { api } from '../../hooks/useApi';
import { useLayout } from '../../providers/LayoutContext';
import calculateColor from '../../utils/calculateColor';
import formatErrorMessage from '../../utils/formatErrorMessage';
import { styles, textStyle } from '../MapWrapperStyles';
import useNotify from '../../hooks/useNotify';

const MeparDataprovider = ({ extent, zoom, layerData, setSource }) => {
    const { selectedProduct } = useLayout();
    const { notifyError } = useNotify();

    const getMepars = useCallback(
        async (controller, params) =>
            api.mepars.listMepars(params, { signal: controller.signal }).then((res) => res.data),
        [api]
    );

    const addStyleToMepars = useCallback(
        (meparsWithoutStyle) => {
            return meparsWithoutStyle.map((e) => {
                let layerPostfix = '';
                if (layerData.isFrostIndexShown) {
                    layerPostfix = `${e.frost ? e.frost.toFixed(2) : ''}`;
                }
                const selectedStyle = e.code === selectedProduct?.code ? styles.selected : {};
                return {
                    ...e,
                    layerPostfix,
                    style: new Style({
                        ...styles.mepar,
                        fill: !layerData.isFrostIndexShown
                            ? styles.mepar.fill
                            : new Fill({
                                  color: calculateColor(e.frost || 0, 11),
                              }),
                        ...selectedStyle,
                        text: new Text({
                            ...textStyle,
                            text: !layerData.isFrostIndexShown
                                ? e.layerName
                                : `${e.layerName}\n${layerPostfix}`,
                        }),
                    }),
                };
            });
        },
        [layerData.isFrostIndexShown, selectedProduct]
    );

    useEffect(() => {
        if (
            (layerData.codes.length === 0 && layerData.connecting === true) ||
            (zoom < 8.5 && layerData.all)
        ) {
            setSource(null);
            return;
        }

        const controller = new AbortController();
        getMepars(
            controller,
            layerData.connecting ? { extent, codes: layerData.codes } : { extent }
        )
            .then((newMepars) => {
                setSource(addStyleToMepars(newMepars));
            })
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(formatErrorMessage(err));
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getMepars, extent, addStyleToMepars, layerData, setSource]);

    return null;
};

export default MeparDataprovider;
