import { Style, Text } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import { useLayout } from '../../providers/LayoutContext';
import formatErrorMessage from '../../utils/formatErrorMessage';
import { styles, textStyle } from '../MapWrapperStyles';

export default function FieldDataprovider({ extent, zoom, layerData, setSource }) {
    const { notifyError } = useNotify();
    const { selectedProduct } = useLayout();

    const getFields = useCallback(
        async (controller, params) =>
            api.fields.listFields(params, { signal: controller.signal }).then((res) => res.data),
        [api]
    );

    const addStyleToFields = useCallback(
        (meparsWithoutStyle) =>
            meparsWithoutStyle.map((e) => {
                const selectedStyle = e.code === selectedProduct?.code ? styles.selected : {};

                return {
                    ...e,
                    style: new Style({
                        ...styles.field,
                        ...selectedStyle,
                        text: new Text({
                            ...textStyle,
                            text: e.layerName,
                        }),
                    }),
                };
            }),
        [selectedProduct]
    );

    useEffect(() => {
        // TODO: active not implemented yet
        if (
            layerData.active ||
            (zoom < 8.5 && layerData.all) ||
            (layerData.connecting === true && layerData.ids.length === 0)
        ) {
            setSource(null);
            return;
        }

        const controller = new AbortController();
        getFields(controller, layerData.connecting ? { extent, ids: layerData.ids } : { extent })
            .then((newFields) => setSource(addStyleToFields(newFields)))
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(formatErrorMessage(err));
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getFields, extent, layerData, addStyleToFields]);
    return null;
}
