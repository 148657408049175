import { InfoOutlined, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { LAYER_TYPES, Map, PREDEFINED_LAYERS } from 'es-map-widget';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BASE_MAP, LOGGED_IN_USER_KEY, PRODUCT_CONTROLLER_STATE } from '../config';
import { CONTROLLER_KEYS, OVERVIEW_STATES } from '../constants';
import { MAP_LAYER_NAMES } from '../constants/MapLayerNames';
import { useAppControl } from '../providers/AppContext';
import './map.css';
import BaseMapController from './mapcontroller/maincontrollers/BaseMapController';
import AlertAreaDataprovider from './mapdata/AlertAreaDataprovider';
import AlertWmsDataprovider from './mapdata/AlertWmsDataprovider';
import AreaRatingDataprovider from './mapdata/AreaRatingDataprovider';
import CropDataprovider from './mapdata/CropDataprovider';
import DroughtDataprovider from './mapdata/DroughtDataprovider';
import FieldDataprovider from './mapdata/FieldDataprovider';
import HarvestDataprovider from './mapdata/HarvestDataprovider';
import MeparDataprovider from './mapdata/MeparDataprovider';
import ZoneDataprovider from './mapdata/ZoneDataprovider';
import MapLegend from './maplegend/MapLegend';

/**
 * Application layer for the Map, nothing below this should be exposed to the app and should move
 * to the map component
 * @returns {JSX.Element}
 * @constructor
 */
const MapWrapper = ({ overviewRef }) => {
    const baseMap = useMemo(() => localStorage.getItem(BASE_MAP) || 'OSM', []);
    const user = useMemo(() => JSON.parse(localStorage.getItem(LOGGED_IN_USER_KEY)), []);
    const controllerState = useMemo(
        () => JSON.parse(localStorage.getItem(PRODUCT_CONTROLLER_STATE)),
        []
    );

    const { overviewState } = useAppControl();
    const [legendOpen, setLegendOpen] = useState(false);
    const [legendFromBottom, setLegendFromBottom] = useState(16);

    const onToggleLegend = useCallback(
        (shouldOpen) => {
            setLegendOpen(shouldOpen);
        },
        [setLegendOpen]
    );

    useEffect(() => {
        if (overviewRef.current && overviewState === OVERVIEW_STATES.OPEN) {
            setLegendFromBottom(overviewRef.current.clientHeight + 32);
            return;
        }
        if (overviewRef.current && overviewState === OVERVIEW_STATES.HIDDEN) {
            setLegendFromBottom(overviewRef.current.clientHeight + 32);
            return;
        }
        setLegendFromBottom(16);
    }, [overviewRef, overviewState]);

    const getMapLayerDefinition = useCallback(() => {
        return {
            [MAP_LAYER_NAMES.OSM]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.OSM,
                defaultVisibility: baseMap === MAP_LAYER_NAMES.OSM,
            },
            [MAP_LAYER_NAMES.BING]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.BING,
                sourceSettings: {
                    key: 'AkeEA2smohBmDAMR5JG4WOP2xCSL2VIAqufB5Yz71BdMd8IwakpqUMjnHrY00VsE',
                    imagerySet: 'Aerial',
                },
                defaultVisibility: baseMap === MAP_LAYER_NAMES.BING,
            },
            [MAP_LAYER_NAMES.ORTHO_PHOTO]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/orthoPhoto/{z}/{x}/{-y}/image.png?token=${user.token}`,
                    maxZoom: 20,
                },
                defaultVisibility: false,
            },
            [MAP_LAYER_NAMES.FROST]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/frostMap/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility: controllerState[CONTROLLER_KEYS.RISK_FACTOR].includes(
                    CONTROLLER_KEYS.FROST
                ),
            },
            [MAP_LAYER_NAMES.WATER_LOGGING]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/waterLogging/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility: controllerState[CONTROLLER_KEYS.RISK_FACTOR].includes(
                    CONTROLLER_KEYS.WATER_LOGGING
                ),
            },
            [MAP_LAYER_NAMES.TERRAIN_MODEL]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/terrainModel/{z}/{x}/{-y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility: controllerState[CONTROLLER_KEYS.SOIL_AND_TERRAIN].includes(
                    CONTROLLER_KEYS.MICRO_TERRAIN
                ),
            },
            [MAP_LAYER_NAMES.EROSION]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/erosion/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility: controllerState[CONTROLLER_KEYS.SOIL_AND_TERRAIN].includes(
                    CONTROLLER_KEYS.EROSION
                ),
            },
            [MAP_LAYER_NAMES.YIELD_MAP_CORN]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/yieldMapCorn/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility:
                    controllerState[CONTROLLER_KEYS.YIELD_MAP] === CONTROLLER_KEYS.CORN,
            },
            [MAP_LAYER_NAMES.YIELD_MAP_SUNFLOWER]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/yieldMapSunflower/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility:
                    controllerState[CONTROLLER_KEYS.YIELD_MAP] === CONTROLLER_KEYS.SUNFLOWER,
            },
            [MAP_LAYER_NAMES.YIELD_MAP_RAPESEED]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/yieldMapRapeseed/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility:
                    controllerState[CONTROLLER_KEYS.YIELD_MAP] === CONTROLLER_KEYS.RAPE_SEED,
            },
            [MAP_LAYER_NAMES.YIELD_MAP_WHEAT]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/yieldMapWheat/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility:
                    controllerState[CONTROLLER_KEYS.YIELD_MAP] === CONTROLLER_KEYS.WHEAT,
            },
            [MAP_LAYER_NAMES.YIELD_MAP_CORN_PREDICTIVE]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/yieldMapCornPredictive/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility:
                    controllerState[CONTROLLER_KEYS.YIELD_PREDICTIVE] === CONTROLLER_KEYS.CORN,
            },
            [MAP_LAYER_NAMES.YIELD_MAP_SUNFLOWER_PREDICTIVE]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/yieldMapSunflowerPredictive/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility:
                    controllerState[CONTROLLER_KEYS.YIELD_PREDICTIVE] === CONTROLLER_KEYS.SUNFLOWER,
            },
            [MAP_LAYER_NAMES.YIELD_MAP_RAPESEED_PREDICTIVE]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/yieldMapRapeseedPredictive/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility:
                    controllerState[CONTROLLER_KEYS.YIELD_PREDICTIVE] === CONTROLLER_KEYS.RAPE_SEED,
            },
            [MAP_LAYER_NAMES.YIELD_MAP_WHEAT_PREDICTIVE]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/yieldMapWheatPredictive/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility:
                    controllerState[CONTROLLER_KEYS.YIELD_PREDICTIVE] === CONTROLLER_KEYS.WHEAT,
            },
            [MAP_LAYER_NAMES.SOIL_MAP]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/1.0/map/tiles/soilMap/{z}/{x}/{-y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility: controllerState[CONTROLLER_KEYS.SOIL_AND_TERRAIN].includes(
                    CONTROLLER_KEYS.SOIL_MAP
                ),
            },
            [MAP_LAYER_NAMES.ALERT_WMS]: {
                type: LAYER_TYPES.WMS,
                source: AlertWmsDataprovider,
                initialLayerData: {
                    token: user.token,
                    date: new Date(),
                    type: 'BMI',
                },
                defaultVisibility: false,
            },
            [MAP_LAYER_NAMES.DROUGHT]: {
                type: LAYER_TYPES.VECTOR,
                source: DroughtDataprovider,
                initialLayerData: {
                    isDroughtShown: false,
                    droughtDate: new Date(),
                },
                defaultVisibility: false,
            },
            [MAP_LAYER_NAMES.CROP]: {
                type: LAYER_TYPES.VECTOR,
                source: CropDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.CROP]?.length > 0,
                initialLayerData: {
                    selectedCrops: controllerState[CONTROLLER_KEYS.CROP] || [],
                    isLayerVisible: controllerState[CONTROLLER_KEYS.CROP]?.length > 0,
                },
            },
            [MAP_LAYER_NAMES.ZONE]: {
                type: LAYER_TYPES.VECTOR,
                source: ZoneDataprovider,
                initialLayerData: {
                    ids: [],
                    isLayerShown: controllerState[CONTROLLER_KEYS.MONITORING].includes(
                        CONTROLLER_KEYS.ZONE
                    ),
                },
                defaultVisibility: controllerState[CONTROLLER_KEYS.MONITORING].includes(
                    CONTROLLER_KEYS.ZONE
                ),
            },
            [MAP_LAYER_NAMES.HARVEST_MONITORING]: {
                type: LAYER_TYPES.VECTOR,
                source: HarvestDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.MONITORING].includes(
                    CONTROLLER_KEYS.HARVEST_MONITORING
                ),
                initialLayerData: {
                    isLayerShown: controllerState[CONTROLLER_KEYS.MONITORING].includes(
                        CONTROLLER_KEYS.HARVEST_MONITORING
                    ),
                },
            },
            [MAP_LAYER_NAMES.AREA_RATING]: {
                type: LAYER_TYPES.VECTOR,
                source: AreaRatingDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.SOIL_AND_TERRAIN].includes(
                    CONTROLLER_KEYS.AREA_RATING
                ),
                initialLayerData: {
                    isLayerShown: controllerState[CONTROLLER_KEYS.SOIL_AND_TERRAIN].includes(
                        CONTROLLER_KEYS.AREA_RATING
                    ),
                },
            },
            [MAP_LAYER_NAMES.ALERT_AREA]: {
                type: LAYER_TYPES.VECTOR,
                source: AlertAreaDataprovider,
                defaultVisibility: false,
                initialLayerData: {
                    area: false,
                },
            },
            [MAP_LAYER_NAMES.MEPAR]: {
                type: LAYER_TYPES.VECTOR,
                source: MeparDataprovider,
                initialLayerData: {
                    all: false,
                    connecting: true,
                    codes: [],
                    isFrostIndexShown: false,
                },
            },
            [MAP_LAYER_NAMES.FIELDS]: {
                type: LAYER_TYPES.VECTOR,
                source: FieldDataprovider,
                initialLayerData: { all: false, connecting: true, active: false, ids: [] },
            },
        };
    }, []);
    return (
        <>
            {legendOpen && <MapLegend bottom={legendFromBottom} />}
            <Fab
                variant="extended"
                aria-label="Jelmagyarázat"
                style={{
                    position: 'absolute',
                    bottom: legendFromBottom,
                    left: 32,
                    backgroundColor: 'white',
                    borderRadius: '10px',
                }}
                onClick={() => onToggleLegend(!legendOpen)}
            >
                <InfoOutlined sx={{ mr: 1 }} />
                Jelmagyarázat
                {legendOpen ? (
                    <KeyboardDoubleArrowDown sx={{ ml: 1 }} />
                ) : (
                    <KeyboardDoubleArrowUp sx={{ ml: 1 }} />
                )}
            </Fab>
            <BaseMapController bottom={legendFromBottom} />
            <Map layers={getMapLayerDefinition()} />
        </>
    );
};

export default MapWrapper;
