import { getYear } from 'date-fns';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OVERVIEW_STATES } from '../constants';

const AppContext = createContext();
AppContext.displayName = 'AppContext';

export function AppContextProvider({ children }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const getYearFromSearch = useCallback(() => {
        const yearFromSearch = searchParams.get('year');
        if (yearFromSearch !== null && !Number.isNaN(parseInt(yearFromSearch, 10))) {
            return parseInt(yearFromSearch, 10);
        }
        return false;
    }, [searchParams]);

    const [year, setYear] = useState(() => getYearFromSearch() || getYear(new Date()));
    const [overviewState, setOverviewState] = useState(OVERVIEW_STATES.CLOSED);

    useEffect(() => {
        setSearchParams({ year }, { replace: true });
    }, [year, setSearchParams]);

    const contextValue = useMemo(
        () => ({
            year,
            setYear,
            overviewState,
            setOverviewState,
        }),
        [year, setYear, overviewState, setOverviewState]
    );

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
}

export function useAppControl() {
    return useContext(AppContext);
}
