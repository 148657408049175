import { FormControl, MenuItem, Select } from '@mui/material';
import { getYear } from 'date-fns';
import React from 'react';
import { useAppControl } from '../../providers/AppContext';

const YearSelector = () => {
    const { year, setYear } = useAppControl();
    const currentDate = getYear(new Date());
    const startDate = 1969;

    return (
        <FormControl
            fullWidth
            sx={{
                maxWidth: '150px',
                mr: 2,
                borderRadius: '8px',
                background: '#FFFFFF',
                color: '#000',
            }}
        >
            <Select
                className="year-selector"
                fullWidth
                value={year}
                size="small"
                onChange={(e) => {
                    setYear(parseInt(e.target.value, 10));
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 150,
                            backgroundColor: 'white',
                        },
                    },
                }}
            >
                {Array(currentDate - startDate)
                    .fill(0)
                    .map((y, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <MenuItem key={currentDate - i} value={currentDate - i}>
                            {currentDate - i}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default YearSelector;
