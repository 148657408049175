import { Grid } from '@mui/material';
import React from 'react';
import { OVERVIEW_STATES } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';
import { useLayout } from '../../../providers/LayoutContext';
import DataChildBlock from './DataChildBlock';

const DataBlock = () => {
    const { selectedProduct } = useLayout();
    const { overviewState } = useAppControl();

    return (
        <Grid
            container
            sx={{
                p: overviewState === OVERVIEW_STATES.HIDDEN ? 0 : 3,
                height: '100%',
                backgroundColor: '#fff',
                overflow: 'auto',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
            }}
            alignItems="flex-start"
            alignContent="flex-start"
        >
            <Grid container item xs={12} direction="column">
                <DataChildBlock type={selectedProduct?.type} />
            </Grid>
        </Grid>
    );
};

export default DataBlock;
