import { format } from 'date-fns';
import { Style, Text } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { cropNumbers } from '../../constants';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import formatErrorMessage from '../../utils/formatErrorMessage';
import { styles } from '../MapWrapperStyles';

export default function HarvestDataprovider({ extent, zoom, layerData, setSource }) {
    const { notifyError } = useNotify();

    const getHarvestLayer = useCallback(
        async (controller, params) =>
            api.map.getMapHarvest(params, { signal: controller.signal }).then((res) => res.data),
        [api]
    );

    const addStyleToHarvests = (zonesWithoutStyle) =>
        zonesWithoutStyle.map((e) => ({
            ...e,
            style: new Style({
                ...styles[cropNumbers[e.crop]],
                text: new Text({
                    text: e.dateBefore && e.dateAfter ? `${e.dateBefore} -\n${e.dateAfter}` : '',
                }),
            }),
        }));

    useEffect(() => {
        if (layerData.isLayerShown === false || zoom < 8.5) {
            setSource(null);
            return;
        }

        const controller = new AbortController();

        getHarvestLayer(controller, {
            extent,
            date: format(layerData?.date ? new Date(layerData?.date) : new Date(), 'yyyy-MM-dd'),
        })
            .then((newHarvestLayer) => {
                setSource(addStyleToHarvests(newHarvestLayer));
            })
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(formatErrorMessage(err));
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getHarvestLayer, extent, layerData, setSource, addStyleToHarvests]);
    return null;
}
