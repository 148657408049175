const OVERVIEW_STATES = {
    OPEN: 'OPEN',
    OPEN_FULLSCREEN: 'OPEN_FULLSCREEN',
    HIDDEN: 'HIDDEN',
    CLOSED: 'CLOSED',
    NONE: false,
};

// eslint-disable-next-line import/prefer-default-export
export { OVERVIEW_STATES };
